
import { defineComponent, onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofGrowthGraph,
  RoofTable,
  RoofGrowthBox,
  RoofFinancialChart,
  RoofNormalText,
} from '@squareroof-admin/ui-components'
import moment from 'moment'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofTable,
    RoofGrowthBox,
    RoofGrowthGraph,
    RoofFinancialChart,
    RoofNormalText,
  },
  middlewares: {
    fetchRules: [
      {
        domain: 'Dashboard',
        property: 'DashboardOverview',
        method: 'GetDashboardOverview',
        params: [10],
        requireAuth: true,
      },
    ],
  },
  name: 'IndexPage',
  layout: 'Dashboard',
  setup() {
    useMeta({
      title: 'Dashboard',
    })

    const DashboardOverview = ref(Logic.Dashboard.DashboardData)
    const UserGrowthData = ref(Logic.Users.UserGrowthData)
    const CreditDebitStatisticsData = ref(
      Logic.Financials.CreditDebitStatisticsData
    )

    const latestBookings = DashboardOverview.value?.GetBookings

    onMounted(() => {
      scrollToTop()
      Logic.Dashboard.watchProperty('DashboardData', DashboardOverview)
      Logic.Users.watchProperty('UserGrowthData', UserGrowthData)
      Logic.Financials.watchProperty(
        'CreditDebitStatisticsData',
        CreditDebitStatisticsData
      )
    })

    return {
      moment,
      DashboardOverview,
      Logic,
      latestBookings,
      UserGrowthData,
      CreditDebitStatisticsData,
    }
  },
})
