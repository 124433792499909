
import { capitalize, defineComponent, onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofTable,
  RoofButton,
  RoofIcon,
  RoofPageData,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'
import { PageData } from '@unwind-admin/logic/src/logic/types/common'
import { editService } from '@/composables/services'
import { showAddTransaction } from '@/composables/admin'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofTable,
    RoofButton,
    RoofIcon,
    RoofPageData,
  },
  name: 'UserInfoPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Users',
        property: 'EachUser',
        method: 'GetUser',
        params: [],
        requireAuth: true,
        ignoreProperty: true,
        useRouteId: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'User details',
    })

    const pageData = ref<PageData[]>([])

    const EachUser = ref(Logic.Users.EachUser)

    const PageBookings = Logic.Users.PageBookings
    const PageActivities = Logic.Users.PageActivities

    const handleState = (state: string) => {
      if (state == 'active') return 'green'
      if (state == 'inactive') return 'red'
      if (state == 'yes') return 'green'
      if (state == 'no') return 'red'
      return 'orange'
    }

    const setPageData = () => {
      pageData.value.length = 0

      // booking info
      pageData.value.push({
        title: 'User information',
        items: [
          {
            title: 'Name',
            content: `${EachUser.value.full_name}`,
            type: 'plain_string',
          },
          {
            title: 'Profile Image',
            content: `${EachUser.value.profile.photo_url}`,
            type: 'single_image',
          },
          {
            title: 'Email',
            content: `${EachUser.value.email}`,
            type: 'plain_string',
          },
          {
            title: 'Phone number',
            content: `${EachUser.value.phone}`,
            type: 'plain_string',
          },
          {
            title: 'Email verified',
            content: `${EachUser.value.email_verified_at ? 'Yes' : 'No'}`,
            color: handleState(EachUser.value.email_verified_at ? 'yes' : 'no'),
            type: 'bagde',
          },
          {
            title: 'Phone verified',
            content: `${EachUser.value.phone_verified_at ? 'Yes' : 'No'}`,
            color: handleState(EachUser.value.phone_verified_at ? 'yes' : 'no'),
            type: 'bagde',
          },
          {
            title: 'Status',
            content: `${capitalize(EachUser.value.status)}`,
            color: handleState(EachUser.value.status),
            type: 'bagde',
          },
          {
            title: 'Wallet balance',
            content: `${Logic.Common.convertToMoney(
              EachUser.value.wallet?.total_balance || 0,
              false,
              '',
              false,
              'NGN '
            )}`,
            type: 'plain_string',
          },
          {
            title: 'Joined on',
            content: `${Logic.Common.fomartDate(
              EachUser.value.created_at,
              'MMM DD, YYYY, hh:MMa'
            )}`,
            type: 'plain_string',
          },
        ],
      })
    }

    const extraQuery = ` hasUser: {
        column: UUID,
        operator: EQ,
        value: "${EachUser.value.uuid}"
      }`

    onMounted(() => {
      scrollToTop()
      Logic.Users.watchProperty('EachUser', EachUser)
      setPageData()
    })

    watch(EachUser, () => {
      setPageData()
    })

    const changeToProvider = () => {
      Logic.Admins.UpdateUserRoleForm.user_uuid = EachUser.value.uuid
      Logic.Admins.UpdateUserRoleForm.role = 'provider'

      Logic.Admins.UpdateUserRole(true)
    }

    return {
      Logic,
      pageData,
      EachUser,
      PageBookings,
      PageActivities,
      editService,
      extraQuery,
      changeToProvider,
      showAddTransaction,
    }
  },
})
