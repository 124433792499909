
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofTable,
  RoofButton,
  RoofIcon,
  // RoofPageData,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'
import { PageData } from '@unwind-admin/logic/src/logic/types/common'
import { showEditServiceCategory } from '@/composables/services'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofTable,
    RoofButton,
    RoofIcon,
    // RoofPageData,
  },
  name: 'ServiceCategoryInfoPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Product',
        property: 'EachServiceCategory',
        method: 'GetServiceCategory',
        params: [],
        requireAuth: true,
        ignoreProperty: true,
        useRouteId: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Service category details',
    })

    const pageData = ref<PageData[]>([])

    const EachServiceCategory = ref(Logic.Product.EachServiceCategory)

    const PageServices = Logic.Product.PageServices

    const setPageData = () => {
      pageData.value.length = 0

      // booking info
      pageData.value.push({
        title: 'Category information',
        items: [
          {
            title: 'Name',
            content: `${EachServiceCategory.value.name}`,
            type: 'plain_string',
          },
          {
            title: 'Assessment',
            content: `${
              EachServiceCategory.value.assessment
                ? EachServiceCategory.value.assessment.name
                : 'Not set'
            }`,
            type: 'link',
            routeLink: `/services/assessments`,
          },
          {
            title: 'Services',
            content: `${EachServiceCategory.value.services.length}`,
            type: 'plain_string',
          },
          {
            title: 'Added on',
            content: `${Logic.Common.fomartDate(
              EachServiceCategory.value.created_at,
              'MMM DD, YYYY, hh:MMa'
            )}`,
            type: 'plain_string',
          },
          {
            title: 'Last updated',
            content: `${Logic.Common.fomartDate(
              EachServiceCategory.value.updated_at,
              'MMM DD, YYYY, hh:MMa'
            )}`,
            type: 'plain_string',
          },
        ],
      })
    }

    const extraQuery = ` hasCategory: { column: UUID, operator: EQ, value:
        "${EachServiceCategory.value.uuid}" }`

    onMounted(() => {
      scrollToTop()
      setPageData()
      Logic.Product.watchProperty('EachServiceCategory', EachServiceCategory)
      Logic.Product.GetAllServiceCategories()
    })

    watch(EachServiceCategory, () => {
      setPageData()
    })

    return {
      Logic,
      pageData,
      EachServiceCategory,
      PageServices,
      showEditServiceCategory,
      extraQuery,
    }
  },
})
