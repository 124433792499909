
import { defineComponent, onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofTable,
  RoofGrowthBox,
  RoofFinancialChart,
  RoofButton,
} from '@squareroof-admin/ui-components'
import moment from 'moment'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'
import { SelectOption } from '@squareroof-admin/ui-components/src/types'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofTable,
    RoofGrowthBox,
    RoofFinancialChart,
    RoofButton,
  },
  name: 'FinancialIndexPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Financials',
        property: 'FinancialData',
        method: 'GetFinancialData',
        params: [10],
        requireAuth: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Financial',
    })

    const financialData = ref(Logic.Financials.FinancialData)
    const PendingBookingPayouts = ref(Logic.Financials.PendingBookingPayouts)
    const CreditDebitStatisticsData = ref(
      Logic.Financials.CreditDebitStatisticsData
    )

    onMounted(() => {
      scrollToTop()
      Logic.Financials.watchProperty(
        'CreditDebitStatisticsData',
        CreditDebitStatisticsData
      )
    })

    const extraBookingQuery = `where: {
        column: STATE,
        operator: EQ,
        value: "completed",
        AND: {
          column: PROVIDER_SETTLED,
          operator: EQ,
          value: false
        }
      }`

    // eslint-disable-next-line @typescript-eslint/ban-types
    const updatePayoutSchedule = (checkFormState: Function) => {
      if (checkFormState()) {
        const payoutScheduleForm = Logic.Product.PayoutScheduleForm
        if (Logic.Common.sideBarInfo.formField) {
          const formFields = Logic.Common.sideBarInfo.formField
          payoutScheduleForm.payout_day = formFields[0].value
          payoutScheduleForm.payout_hour = formFields[1].value
        }
        Logic.Common.sideBarInfo = Logic.Common.updatedData(
          Logic.Common.sideBarInfo,
          {
            formIsLoading: true,
          }
        )

        Logic.Product.UpdatePayoutSchedule(true)
      }
    }

    const showUpdatePayoutSchedule = () => {
      const dayOptions: SelectOption[] = [
        {
          key: 'sunday',
          value: 'Sunday',
        },
        {
          key: 'monday',
          value: 'Monday',
        },
        {
          key: 'tuesday',
          value: 'Tuesday',
        },
        {
          key: 'wednesday',
          value: 'Wednesday',
        },
        {
          key: 'thursday',
          value: 'Thursday',
        },
        {
          key: 'friday',
          value: 'Friday',
        },
        {
          key: 'saturday',
          value: 'Saturday',
        },
      ]

      const payoutTime: SelectOption[] = []
      for (let index = 0; index < 24; index++) {
        const timeValue = index >= 12 ? 'pm' : 'am'
        payoutTime.push({
          key: `${index}`,
          value: `${index % 12 == 0 ? '12' : index % 12}:00 ${timeValue}`,
        })
      }

      Logic.Common.showSideBar({
        show: true,
        isForm: true,
        title: 'Update payout schedule',
        formActionLabel: 'Update',
        formAction: updatePayoutSchedule,
        formIsLoading: false,
        formField: [
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Payout Day',
            placeholder: 'Select day of the week',
            ref: 'payout_day',
            type: 'select',
            validations: [Logic.Form.RequiredRule],
            selectOption: dayOptions,
            value: `${financialData.value.GetFinancialsOverview.payout_day}`,
            name: 'Payout Day',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Payout Time',
            placeholder: 'Select time of the day',
            ref: 'payout_time',
            type: 'select',
            validations: [Logic.Form.RequiredRule],
            selectOption: payoutTime,
            value: `${financialData.value.GetFinancialsOverview.payout_time}`,
            name: 'Payout Time',
            hasTitle: true,
          },
        ],
      })
    }

    return {
      moment,
      financialData,
      Logic,
      CreditDebitStatisticsData,
      PendingBookingPayouts,
      extraBookingQuery,
      showUpdatePayoutSchedule,
    }
  },
})
