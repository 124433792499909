
import { capitalize, defineComponent, onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofTable,
  RoofButton,
  // RoofNormalText,
  // RoofBadge,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofButton,
    // RoofNormalText,
    // RoofBadge,
    RoofTable,
  },
  name: 'ReferralsPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Users',
        property: 'Influencers',
        method: 'GetInfluencers',
        params: [10, 1, 'CREATED_AT', 'DESC'],
        requireAuth: true,
      },
      {
        domain: 'Users',
        property: 'ReferredUsers',
        method: 'GetReferredUsers',
        params: [10, 1, 'CREATED_AT', 'DESC'],
        requireAuth: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Referrals',
    })

    const Influencers = ref(Logic.Users.Influencers)
    const ReferredUsers = ref(Logic.Users.ReferredUsers)

    onMounted(() => {
      console.log(
        Logic.Users.InfluencerTableStructure,
        Influencers,
        Logic.Users.InfluencerSource
      )
      console.log(
        Logic.Users.ReferredUserTableStructure,
        ReferredUsers,
        Logic.Users.ReferredUserSource
      )
      scrollToTop()
      Logic.Users.watchProperty('Influencers', Influencers)
      Logic.Users.watchProperty('ReferredUsers', ReferredUsers)
    })

    // eslint-disable-next-line @typescript-eslint/ban-types
    const createInfluencer = (checkFormState: Function) => {
      if (checkFormState()) {
        const createInfluencerForm = Logic.Users.CreateInfluencerForm
        if (Logic.Common.sideBarInfo.formField) {
          const formFields = Logic.Common.sideBarInfo.formField
          createInfluencerForm.name = formFields[0].value
          createInfluencerForm.email = formFields[1].value
          createInfluencerForm.phone_number = formFields[2].value
          createInfluencerForm.referral_code = formFields[3].value
          createInfluencerForm.bank = formFields[4].value
          createInfluencerForm.bank_account = formFields[5].value
        }
        Logic.Common.sideBarInfo = Logic.Common.updatedData(
          Logic.Common.sideBarInfo,
          {
            formIsLoading: true,
          }
        )
        Logic.Users.CreateInfluencer(true)
      }
    }

    const showCreateInfluencer = () => {
      Logic.Common.showSideBar({
        show: true,
        isForm: true,
        title: 'Create Influencer',
        formActionLabel: 'Create',
        formAction: createInfluencer,
        formIsLoading: false,
        formField: [
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Name',
            placeholder: 'Enter name',
            ref: 'name',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            value: ``,
            name: 'Name',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Email',
            placeholder: 'Enter email',
            ref: 'email',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            value: ``,
            name: 'Email',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Phone number',
            placeholder: 'Enter phone number',
            ref: 'phone_number',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            value: ``,
            name: 'Phone Number',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Referral code',
            placeholder: 'Set referral code',
            ref: 'referral_code',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            value: ``,
            name: 'Referral code',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Bank',
            placeholder: 'Enter bank name',
            ref: 'bank',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            value: ``,
            name: 'Bank',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Account number',
            placeholder: 'Enter account number',
            ref: 'bank_account',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            value: ``,
            name: 'Bank Account',
            hasTitle: true,
          },
        ],
      })
    }

    return {
      Logic,
      showCreateInfluencer,
      Influencers,
      ReferredUsers,
      capitalize,
    }
  },
})
