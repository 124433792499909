
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  // RoofNormalText,
  // RoofSelect,
  // RoofTreemapChart,
  RoofGrowthBox,
  RoofGrowthGraph,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'

export default defineComponent({
  components: {
    RoofHeaderText,
    // RoofNormalText,
    // RoofSelect,
    // RoofTreemapChart,
    RoofGrowthBox,
    RoofGrowthGraph,
  },
  name: 'UsersIndexPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Users',
        property: 'UsersOverviewData',
        method: 'GetUsersOverviewData',
        params: [10],
        requireAuth: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Users',
    })

    const userOverview = Logic.Users.UsersOverviewData

    // const taskAnalysisStats = reactive({
    //   most: '',
    //   least: '',
    // })

    // const dropoffStats = reactive({
    //   highest: '',
    //   lowest: '',
    // })

    // const tasks = [
    //   'Onboarding',
    //   'Fund Wallet',
    //   'Create Home Equity',
    //   'Fund Plan',
    //   'Pay Rent',
    //   'Create Home Rent Savings',
    //   'Create Shop Rent Savings',
    //   'Pay Electricity Bill',
    //   'Make Mobile Topup',
    //   'Pay TV Bill',
    //   'Create Rental Income',
    // ]

    // const dropOffData = ref<any>()

    // const generateDropOffData = () => {
    //   const dropOffRawData = userOverview?.GetUserDropOffPointAnalysis
    //   dropOffRawData?.forEach((item) => {
    //     item.value =
    //       typeof item.value == 'string' ? JSON.parse(item.value) : item.value
    //   })

    //   dropOffData.value = {
    //     children: [],
    //     name: 'root',
    //   }

    //   const allDropoffs: any[] = []

    //   dropOffRawData?.forEach((product) => {
    //     const dropOffPoints: any = []

    //     let currentDropoffTasks: any = product.value

    //     if (currentDropoffTasks.length == undefined) {
    //       currentDropoffTasks = Object.keys(currentDropoffTasks).map((key) => [
    //         key,
    //         currentDropoffTasks[key],
    //       ])
    //     }

    //     let dropOffs = 0

    //     currentDropoffTasks.forEach((point: any) => {
    //       dropOffPoints.push({
    //         name: point[0],
    //         value: point[1],
    //       })
    //       dropOffs += point[1]
    //     })

    //     allDropoffs.push({
    //       name: product.name,
    //       count: dropOffs,
    //     })

    //     dropOffData.value.children.push({
    //       name: product.name,
    //       children: dropOffPoints,
    //     })
    //   })

    //   function compare(a: any, b: any) {
    //     if (a.count < b.count) {
    //       return -1
    //     }
    //     if (a.count > b.count) {
    //       return 1
    //     }
    //     return 0
    //   }

    //   allDropoffs.sort(compare)

    //   const productNames = Object.values(allDropoffs)

    //   dropoffStats.lowest = productNames[0] ? productNames[0].name : 'Not Set'
    //   dropoffStats.highest = productNames[productNames.length - 1]
    //     ? productNames[productNames.length - 1].name
    //     : 'Not Set'
    // }

    // const tasksData = ref<any>()

    // const generateTasksData = () => {
    //   const taskAnalysisData = userOverview?.GetUserTaskAnalysis

    //   tasksData.value = {
    //     children: [],
    //     name: 'root',
    //   }

    //   taskAnalysisData?.forEach((task) => {
    //     const eachPoints: any = []

    //     eachPoints.push({
    //       name: 'Completed',
    //       value: task.completed,
    //     })

    //     eachPoints.push({
    //       name: 'Dropoff',
    //       value: task.dropoff,
    //     })

    //     tasksData.value.children.push({
    //       name: task.name,
    //       children: eachPoints,
    //     })
    //   })

    //   function compare(a: any, b: any) {
    //     if (a.completed < b.completed) {
    //       return -1
    //     }
    //     if (a.completed > b.completed) {
    //       return 1
    //     }
    //     return 0
    //   }

    //   taskAnalysisData?.sort(compare)

    //   if (taskAnalysisData) {
    //     taskAnalysisStats.least = taskAnalysisData[0]
    //       ? taskAnalysisData[0].name
    //       : 'Not Set'
    //     taskAnalysisStats.most = taskAnalysisData[taskAnalysisData.length - 1]
    //       ? taskAnalysisData[taskAnalysisData.length - 1].name
    //       : 'Not Set'
    //   }
    // }

    onMounted(() => {
      // generateTasksData();
      // generateDropOffData();
      scrollToTop()
    })

    return {
      userOverview,
      // tasks,
      // tasksData,
      // dropOffData,
      // taskAnalysisStats,
      // dropoffStats,
    }
  },
})
