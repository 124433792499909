
import { defineComponent, onMounted, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import {
  RoofHeaderText,
  RoofButton,
  RoofNormalText,
  RoofBadge,
  RoofTextarea,
  RoofMultipleChoice,
} from "@squareroof-admin/ui-components";
import { Logic } from "@unwind-admin/logic";
import { scrollToTop } from "@/composables";
import draggable from "vuedraggable";
import {
  AssessmentQuestion,
  ServiceAssessment,
} from "@unwind-admin/logic/src/gql/graphql";
import { notify } from "@kyvg/vue3-notification";

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofButton,
    RoofNormalText,
    RoofBadge,
    RoofTextarea,
    RoofMultipleChoice,
    draggable,
  },
  name: "ServiceAssessmentPage",
  layout: "Dashboard",
  middlewares: {
    fetchRules: [
      {
        domain: "Product",
        property: "ServiceAssessments",
        method: "GetServiceAssessments",
        params: [50, 1, "CREATED_AT", "DESC"],
        requireAuth: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: "Service Assessments",
    });

    onMounted(() => {
      scrollToTop();
    });

    const ServiceAssessments = ref(Logic.Product.ServiceAssessments);

    const selectedAssessment = ref("");

    const selectedTab = ref("notes");

    const noteContent = ref("");

    const updatedContent = ref("");

    const allSelectedQuestions = ref<AssessmentQuestion[]>([]);

    const selectedServiceAssessment = ref<ServiceAssessment>();

    const deleteState = ref("inactive");

    const saveAssessmentNote = () => {
      selectedServiceAssessment.value = ServiceAssessments.value?.data.filter(
        (assessment) => {
          return assessment.uuid == selectedAssessment.value;
        }
      )[0];

      const updateServiceAssessmentForm =
        Logic.Product.UpdateServiceAssessmentForm;
      updateServiceAssessmentForm.service_assessment_uuid =
        selectedServiceAssessment.value
          ? selectedServiceAssessment.value.uuid
          : "";
      updateServiceAssessmentForm.notes = noteContent.value;

      Logic.Product.ServiceAssessments?.data.forEach((assessment) => {
        if (
          assessment.uuid == updateServiceAssessmentForm.service_assessment_uuid
        ) {
          assessment.notes = updateServiceAssessmentForm.notes;
        }
      });

      Logic.Product.UpdateServiceAssessment(true);
    };

    watch(noteContent, () => {
      if (noteContent.value) {
        saveAssessmentNote();
      }
    });

    watch(selectedTab, () => {
      if (selectedTab.value == "notes") {
        updatedContent.value = "";
        setTimeout(() => {
          setAssessmentNote();
        }, 200);
      }
    });

    const setAssessmentNote = () => {
      if (selectedAssessment.value) {
        updatedContent.value = "";
        selectedServiceAssessment.value = ServiceAssessments.value?.data.filter(
          (assessment) => {
            return assessment.uuid == selectedAssessment.value;
          }
        )[0];

        if (selectedServiceAssessment.value) {
          updatedContent.value = selectedServiceAssessment.value.notes
            ? selectedServiceAssessment.value.notes
            : "";
        }

        allSelectedQuestions.value = selectedServiceAssessment.value.questions;
      }
    };

    watch(selectedAssessment, () => {
      setAssessmentNote();
    });

    // eslint-disable-next-line @typescript-eslint/ban-types
    const saveServiceAssessment = (checkFormState: Function) => {
      if (checkFormState()) {
        const AddServiceAssessmentForm = Logic.Product.AddServiceAssessmentForm;
        if (Logic.Common.sideBarInfo.formField) {
          AddServiceAssessmentForm.name =
            Logic.Common.sideBarInfo.formField[0].value;

          AddServiceAssessmentForm.pass_percentage =
            Logic.Common.sideBarInfo.formField[1].value;

          AddServiceAssessmentForm.service_category_id = parseFloat(
            Logic.Common.sideBarInfo.formField[2].value
          );
        }
        Logic.Common.sideBarInfo = Logic.Common.updatedData(
          Logic.Common.sideBarInfo,
          {
            formIsLoading: true,
          }
        );
        Logic.Product.AddServiceAssessment(true);
      }
    };

    const addServiceAssessment = () => {
      Logic.Product.GetAllServiceCategories().then(() => {
        Logic.Product.ServiceCategoriesOptions.unshift({
          key: 0,
          value: "All Services",
        });
      });

      Logic.Common.showSideBar({
        show: true,
        isForm: true,
        title: "Add New Assessment",
        formActionLabel: "Add",
        formAction: saveServiceAssessment,
        formIsLoading: false,
        formField: [
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Name",
            placeholder: "Enter assessment name",
            ref: "assessment_name",
            type: "text",
            validations: [Logic.Form.RequiredRule],
            value: "",
            name: "Name",
            hasTitle: true,
          },
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Pass Score in %",
            placeholder: "Set pass score",
            ref: "pass_percentage",
            type: "tel",
            validations: [Logic.Form.RequiredRule],
            value: "",
            name: "Pass Score",
            hasTitle: true,
          },
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Service Category",
            placeholder: "Select category",
            ref: "service_category",
            type: "select",
            validations: [Logic.Form.RequiredRule],
            selectOption: Logic.Product.ServiceCategoriesOptions,
            value: "",
            name: "Service Category",
            hasTitle: true,
          },
        ],
      });
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const updateServiceAssessment = (checkFormState: Function) => {
      if (checkFormState()) {
        const updateServiceAssessmentForm =
          Logic.Product.UpdateServiceAssessmentForm;
        if (Logic.Common.sideBarInfo.formField) {
          updateServiceAssessmentForm.name =
            Logic.Common.sideBarInfo.formField[0].value;

          updateServiceAssessmentForm.pass_percentage =
            Logic.Common.sideBarInfo.formField[1].value;

          if (Logic.Common.sideBarInfo.formField[2].value != "") {
            updateServiceAssessmentForm.service_category_id = parseFloat(
              Logic.Common.sideBarInfo.formField[2].value
            );
          } else {
            updateServiceAssessmentForm.service_category_id = undefined;
          }
        }

        Logic.Common.sideBarInfo = Logic.Common.updatedData(
          Logic.Common.sideBarInfo,
          {
            formIsLoading: true,
          }
        );
        Logic.Product.UpdateServiceAssessment(true, true);
      }
    };

    const showUpdateAssesment = (assessment: ServiceAssessment) => {
      Logic.Product.UpdateServiceAssessmentForm.service_assessment_uuid =
        assessment.uuid;
      Logic.Product.GetAllServiceCategories().then(() => {
        Logic.Product.ServiceCategoriesOptions.unshift(
          {
            key: "-1",
            value: "No Service",
          },
          {
            key: "0",
            value: "All Services",
          }
        );
      });

      Logic.Common.showSideBar({
        show: true,
        isForm: true,
        title: "Update Assessment",
        formActionLabel: "Save changes",
        formAction: updateServiceAssessment,
        formIsLoading: false,
        formField: [
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Name",
            placeholder: "Enter assessment name",
            ref: "assessment_name",
            type: "text",
            validations: [Logic.Form.RequiredRule],
            value: `${assessment.name}`,
            name: "Name",
            hasTitle: true,
          },
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Pass Score in %",
            placeholder: "Set pass score",
            ref: "pass_percentage",
            type: "tel",
            validations: [Logic.Form.RequiredRule],
            value: `${assessment.pass_percentage}`,
            name: "Pass Score",
            hasTitle: true,
          },
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Service Category",
            placeholder: "Select category",
            ref: "service_category",
            type: "select",
            validations: [Logic.Form.RequiredRule],
            selectOption: Logic.Product.ServiceCategoriesOptions,
            value: `${
              assessment.category
                ? assessment.category.id
                : `${assessment.is_general ? "0" : "-1"}`
            }`,
            name: "Service Category",
            hasTitle: true,
          },
        ],
      });
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const saveQuestion = (checkFormState: Function) => {
      if (checkFormState()) {
        const AddQuestionForm = Logic.Product.AddServiceAssessmentQuestionForm;
        if (Logic.Common.sideBarInfo.formField) {
          AddQuestionForm.body = Logic.Common.sideBarInfo.formField[0].value;

          AddQuestionForm.point = parseFloat(
            Logic.Common.sideBarInfo.formField[1].value
          );

          AddQuestionForm.auto_score = true;
          AddQuestionForm.type = "multiple_choice";
          const optionData: any = Logic.Common.sideBarInfo.formField[2].value;

          AddQuestionForm.answer = optionData.answer;
          AddQuestionForm.options = JSON.stringify(optionData.options);

          AddQuestionForm.service_assessment_id =
            selectedServiceAssessment.value
              ? selectedServiceAssessment.value.id
              : "0";

          Logic.Common.sideBarInfo = Logic.Common.updatedData(
            Logic.Common.sideBarInfo,
            {
              formIsLoading: true,
            }
          );
          Logic.Product.AddServiceAssessmentQuestion(true);
        }
      }
    };

    const showAddQuestion = () => {
      Logic.Common.showSideBar({
        show: true,
        isForm: true,
        title: "Add New Question",
        formActionLabel: "Add",
        formAction: saveQuestion,
        formIsLoading: false,
        formField: [
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px] ",
            label: "Question",
            placeholder: "Enter question content",
            ref: "question_body",
            type: "textarea",
            validations: [Logic.Form.RequiredRule],
            value: "",
            name: "Question",
            richEditor: false,
            hasTitle: true,
          },
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Question Point",
            placeholder: "Set point e.g 5",
            ref: "question_point",
            type: "tel",
            validations: [Logic.Form.RequiredRule],
            value: "",
            name: "Point",
            hasTitle: true,
          },
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Options",
            placeholder: "",
            ref: "question_options",
            type: "multipleChoice",
            validations: [],
            value: "",
            name: "Options",
            hasTitle: true,
          },
        ],
      });
    };

    onMounted(() => {
      Logic.Product.watchProperty("ServiceAssessments", ServiceAssessments);

      if (ServiceAssessments.value?.data.length) {
        selectedAssessment.value = ServiceAssessments.value?.data[0].uuid || "";
      }

      setAssessmentNote();
    });

    const updateQuestion = (data: any) => {
      localStorage.setItem(
        `options-${data.extraId}`,
        JSON.stringify({
          options: data.options,
          answer: data.answer,
        })
      );
    };

    const saveQuestionInfo = async (data: any) => {
      const localData: any = localStorage.getItem(`options-${data.uuid}`)
        ? JSON.parse(localStorage.getItem(`options-${data.uuid}`))
        : {};

      const UpdateQuestionForm =
        Logic.Product.UpdateServiceAssessmentQuestionForm;
      UpdateQuestionForm.assessment_question_uuid = data.uuid;
      UpdateQuestionForm.options = JSON.stringify(localData.options);
      UpdateQuestionForm.answer = localData.answer;
      UpdateQuestionForm.body = data.body;
      // console.log(UpdateQuestionForm);
      await Logic.Product.UpdateServiceAssessmentQuestion(true);

      alert("Changes saved");
    };

    const updatingContent = ref(false);

    const questionContentUpdate = (e: any, questions: any) => {
      const textContent = e.target.innerText;

      questions.body = textContent;

      Logic.Common.debounce(() => {
        updatingContent.value = true;
        const UpdateQuestionForm =
          Logic.Product.UpdateServiceAssessmentQuestionForm;
        UpdateQuestionForm.assessment_question_uuid = questions.uuid;
        UpdateQuestionForm.body = textContent;
        Logic.Product.UpdateServiceAssessmentQuestion(true);
      }, 500);
    };

    const deleteAssessment = (uuid: string) => {
      Logic.Product.DeleteAssessement(uuid).then(() => {
        Logic.Product.GetServiceAssessments(100, 1, "CREATED_AT", "DESC").then(
          () => {
            setTimeout(() => {
              selectedAssessment.value =
                ServiceAssessments.value?.data[0].uuid || "";
            }, 1000);
            deleteState.value = "inactive";
          }
        );
      });
    };

    return {
      Logic,
      addServiceAssessment,
      ServiceAssessments,
      selectedAssessment,
      selectedTab,
      noteContent,
      updatedContent,
      setAssessmentNote,
      showAddQuestion,
      selectedServiceAssessment,
      updateQuestion,
      showUpdateAssesment,
      questionContentUpdate,
      deleteAssessment,
      deleteState,
      saveQuestionInfo,
    };
  },
});
