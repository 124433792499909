
import { defineComponent, reactive, ref } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofButton,
  RoofTextField,
  RoofNormalText,
  RoofFormWrapper,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { FormValidations } from '@/composables'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofTextField,
    RoofButton,
    RoofNormalText,
    RoofFormWrapper,
  },
  name: 'LoginPage',
  layout: 'Auth',
  setup() {
    useMeta({
      title: 'Login to your Account',
    })

    const loginFormComp = ref<any>(null)

    const signIndata = reactive({
      email: '',
      password: '',
    })

    const SignInForm = Logic.Auth.SignInForm

    const SignIn = () => {
      const formState: boolean = loginFormComp.value.validate()
      SignInForm.email = signIndata.email
      SignInForm.password = signIndata.password
      Logic.Auth.SignIn(formState)
    }

    return {
      FormValidations,
      SignInForm,
      SignIn,
      loginFormComp,
      Logic,
      signIndata,
    }
  },
  data() {
    return {
      parentRefs: null,
    }
  },
  mounted() {
    const parentRefs: any = this.$refs
    this.parentRefs = parentRefs
  },
})
