
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofSelect,
  RoofGrowthGraph,
  RoofTable,
  RoofGrowthBox,
  RoofActionBox,
  RoofNormalText,
  RoofButton,
} from '@squareroof-admin/ui-components'
import moment from 'moment'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofSelect,
    RoofTable,
    RoofGrowthBox,
    RoofGrowthGraph,
    RoofActionBox,
    RoofNormalText,
    RoofButton,
  },
  middlewares: {
    fetchRules: [
      {
        domain: 'Booking',
        property: 'BookingOverviewData',
        method: 'GetBookingOverviewData',
        params: [10],
        requireAuth: true,
      },
    ],
  },
  name: 'BookingPage',
  layout: 'Dashboard',
  setup() {
    useMeta({
      title: 'Bookings',
    })

    const BookingOverview = ref(Logic.Booking.BookingOverviewData)

    const Bookings = ref(BookingOverview.value?.GetBookings)

    onMounted(() => {
      scrollToTop()
      Logic.Booking.watchProperty('BookingOverviewData', BookingOverview)
    })

    // eslint-disable-next-line @typescript-eslint/ban-types
    const assignProvider = (checkFormState: Function) => {
      if (checkFormState()) {
        const assignBookingToProvider =
          Logic.Provider.AssignProviderToBookingForm

        if (Logic.Common.sideBarInfo.formField) {
          const formFields = Logic.Common.sideBarInfo.formField
          if (formFields[0].value) {
            assignBookingToProvider.provider_user_id = formFields[0].value
            Logic.Provider.AssignProviderToBooking(true)
          }
        }
      }
    }

    const showAssignBooking = (booking_uuid: string) => {
      Logic.Provider.AssignProviderToBookingForm.booking_uuid = booking_uuid
      Logic.Common.showLoader({
        show: true,
        useModal: true,
        loading: true,
      })
      Logic.Provider.GetAvailableProviders(booking_uuid).then((data: any) => {
        if (data) {
          Logic.Common.hideLoader()

          Logic.Common.showSideBar({
            show: true,
            isForm: true,
            title: 'Select Service Provider',
            formActionLabel: 'Assign',
            formAction: assignProvider,
            formIsLoading: false,
            formField: [
              {
                customClass: 'col-span-12 !border-transparent !rounded-[5px]',
                label: 'Service Provider',
                placeholder: 'Select...',
                ref: 'service_provider',
                type: 'select',
                validations: [Logic.Form.RequiredRule],
                selectOption: Logic.Provider.AvailableProvidersOptions,
                value: '',
                name: 'Service Provider',
                hasTitle: true,
              },
            ],
          })
        } else {
          Logic.Common.hideLoader()
        }
      })
    }

    watch(BookingOverview, () => {
      Bookings.value = BookingOverview.value?.GetBookings
    })

    return {
      moment,
      BookingOverview,
      Logic,
      Bookings,
      showAssignBooking,
    }
  },
})
