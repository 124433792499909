
import { defineComponent, onMounted } from 'vue'
import { useMeta } from 'vue-meta'
import { RoofHeaderText, RoofTable } from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofTable,
  },
  name: 'ActivityLogIndexPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Admins',
        property: 'ActivityLogs',
        method: 'GetActivityLogs',
        params: [8, 1, 'CREATED_AT', 'DESC'],
        requireAuth: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Activity Log',
    })

    const allActivityLogs = Logic.Admins.ActivityLogs

    onMounted(() => {
      scrollToTop()
    })

    return {
      allActivityLogs,
      Logic,
    }
  },
})
