
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofButton,
  RoofTextField,
  RoofIcon,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofTextField,
    RoofButton,
    RoofIcon,
  },
  name: 'ForgotPasswordPage',
  layout: 'Auth',
  setup() {
    useMeta({
      title: 'Forgot Password',
    })

    return {
      Logic,
    }
  },
})
