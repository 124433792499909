import AppLayout from './layouts/AppLayout.vue'
import { createApp } from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
import { createRouter, createWebHistory, useRouter } from 'vue-router'
import routes from './router/routes'
import { createMetaManager } from 'vue-meta'
import { Logic } from '@unwind-admin/logic'
import { SetFrontendLogic } from '@squareroof-admin/ui-components'

// UI component css style
import '@squareroof-admin/ui-components/dist/library.min.css'

// You can disable this if you dont want TailwindCss
import './assets/app.css'

import { store, key } from './store'

const router = Promise.all(routes).then((routes) => {
  const router = createRouter({
    history: createWebHistory(),
    routes,
  })

  router.beforeEach((to, from, next) => {
    const toRouter: any = to
    const fromRouter: any = from
    return Logic.Common.preFetchRouteData(toRouter, next, fromRouter)
  })

  return router
})

const init = async () => {
  createApp({
    components: {
      App,
    },
    setup() {
      const router: any = useRouter()
      Logic.Common.SetRouter(router)
      Logic.Common.SetApiUrl(process.env.VUE_APP_API_URL || '')

      // set ui frontend logic
      SetFrontendLogic(Logic)
    },
  })
    .component('AppLayout', AppLayout)
    .use(await router)
    .use(store, key)
    .use(createMetaManager())
    .mount('#app')
}

init()
