import { Logic, SelectOption } from '@unwind-admin/logic'
import { Service, ServiceCategory } from '@unwind-admin/logic/src/gql/graphql'
import { ref, reactive } from 'vue'

const productOverview = ref(Logic.Product.ProductOverviewData)

const ServiceUsageStatistics = ref(Logic.Product.ServiceUsageStatistics)
const ServiceCategoryTransactions = ref(
  Logic.Product.ServiceCategoryTransactions,
)
const ServiceCategoryBookings = ref(Logic.Product.ServiceCategoryBookings)

const transactionStats = reactive({
  max: 0,
  min: 0,
})

const dropoffStats = reactive({
  highest: '',
  lowest: '',
})

const bookingStatData = reactive({
  least: '',
  most: '',
})

const transactionData = ref<any>()

const bookingData = ref<any>()

const statisticsdatesData = ref()
const serviceTransData = ref()
const serviceBookingData = ref()

const statisticsdates = reactive({
  from: Logic.Common.momentInstance(new Date())
    .subtract(1, 'months')
    .format('YYYY-MM-DD'),
  to: Logic.Common.momentInstance(new Date()).format('YYYY-MM-DD'),
})

const serviceTransdates = reactive({
  from: Logic.Common.momentInstance(new Date())
    .subtract(1, 'months')
    .format('YYYY-MM-DD'),
  to: Logic.Common.momentInstance(new Date()).format('YYYY-MM-DD'),
})

const serviceBookingdates = reactive({
  from: Logic.Common.momentInstance(new Date())
    .subtract(1, 'months')
    .format('YYYY-MM-DD'),
  to: Logic.Common.momentInstance(new Date()).format('YYYY-MM-DD'),
})

const setTransactionStats = () => {
  transactionData.value = ServiceCategoryTransactions.value

  const bookingSampleData = JSON.parse(
    JSON.stringify(ServiceCategoryTransactions.value),
  )

  bookingData.value = bookingSampleData?.map((trans) => {
    trans.amount = Math.round(Math.random() * 800)
    return trans
  })

  const allAmounts = transactionData.value?.map((item) => item.amount) || []

  transactionStats.max = Math.max(...allAmounts)
  transactionStats.min = Math.min(...allAmounts)
}

const setBookingStats = () => {
  const bookingData = ServiceCategoryBookings.value
  bookingData.sort(function (a, b) {
    return a.amount - b.amount
  })

  bookingStatData.least = bookingData[0].name
  bookingStatData.most = bookingData[bookingData.length - 1].name
}

interface ChartData {
  direction: string
  data: any[]
  color?: string
  isDate?: boolean
  axisFontSize?: string
}

const productStatsData = ref<ChartData[]>([])

const generateProductStats = () => {
  productStatsData.value.length = 0

  const productData = ServiceUsageStatistics.value

  if (productData) {
    // y axis
    productStatsData.value.push({
      direction: 'y',
      data: productData.map((item) => item.views),
      color: 'rgba(254, 159, 94, 1.0)',
    })

    productStatsData.value.push({
      direction: 'y',
      data: productData.map((item) => item.usage),
      color: 'rgba(64, 159, 255, 1.0)',
    })

    // x axis
    productStatsData.value.push({
      direction: 'x',
      data: productData.map((item) => item.name),
      axisFontSize: '11px',
    })
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const saveServiceCategory = (checkFormState: Function) => {
  if (checkFormState()) {
    const addServiceCategoryForm = Logic.Product.AddServiceCategoryForm
    if (Logic.Common.sideBarInfo.formField) {
      addServiceCategoryForm.name = Logic.Common.sideBarInfo.formField[0].value
    }
    Logic.Common.sideBarInfo = Logic.Common.updatedData(
      Logic.Common.sideBarInfo,
      {
        formIsLoading: true,
      },
    )
    Logic.Product.AddServiceCategory(true)
  }
}

const showAddServiceCategory = () => {
  Logic.Common.showSideBar({
    show: true,
    isForm: true,
    title: 'Add Service Category',
    formActionLabel: 'Add',
    formAction: saveServiceCategory,
    formIsLoading: false,
    formField: [
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Name',
        placeholder: 'Enter category name',
        ref: 'category_name',
        type: 'text',
        validations: [Logic.Form.RequiredRule],
        value: '',
        name: 'Name',
        hasTitle: true,
      },
    ],
  })
}

// eslint-disable-next-line @typescript-eslint/ban-types
const updateServiceCategory = (checkFormState: Function) => {
  if (checkFormState()) {
    const updateServiceCateroryForm = Logic.Product.UpdateServiceCategoryForm
    if (Logic.Common.sideBarInfo.formField) {
      updateServiceCateroryForm.name =
        Logic.Common.sideBarInfo.formField[0].value
      updateServiceCateroryForm.active =
        Logic.Common.sideBarInfo.formField[1].value == 'true'
    }
    Logic.Common.sideBarInfo = Logic.Common.updatedData(
      Logic.Common.sideBarInfo,
      {
        formIsLoading: true,
      },
    )
    Logic.Product.UpdateServiceCategory(true)
  }
}

const showEditServiceCategory = (serviceCategory: ServiceCategory) => {
  const updateServiceCateroryForm = Logic.Product.UpdateServiceCategoryForm

  updateServiceCateroryForm.service_category_uuid = serviceCategory.uuid

  const activationStates: SelectOption[] = [
    {
      key: 'true',
      value: 'Active',
    },
    {
      key: 'false',
      value: 'Inactive',
    },
  ]

  Logic.Common.showSideBar({
    show: true,
    isForm: true,
    title: 'Edit Service Category',
    formActionLabel: 'Update',
    formAction: updateServiceCategory,
    formIsLoading: false,
    formField: [
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Name',
        placeholder: 'Enter category name',
        ref: 'category_name',
        type: 'text',
        validations: [Logic.Form.RequiredRule],
        value: `${serviceCategory.name}`,
        name: 'Name',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Service Category Status',
        placeholder: 'Set status',
        ref: 'service_category_status',
        type: 'select',
        validations: [Logic.Form.RequiredRule],
        selectOption: activationStates,
        value: `${serviceCategory.active}`,
        name: 'Service Category Status',
        hasTitle: true,
      },
    ],
  })
}

const serviceType = [
  {
    key: 'on_demand',
    value: 'On demand',
  },
  {
    key: 'in_person',
    value: 'In Person',
  },
]

// eslint-disable-next-line @typescript-eslint/ban-types
const saveService = (checkFormState: Function) => {
  if (checkFormState()) {
    const addServiceForm = Logic.Product.AddServiceForm
    if (Logic.Common.sideBarInfo.formField) {
      const formFields = Logic.Common.sideBarInfo.formField
      addServiceForm.bg_image = formFields[0].value
      addServiceForm.name = formFields[1].value
      addServiceForm.service_category_id = formFields[2].value

      if (formFields[3].value) {
        addServiceForm.associated_categories = JSON.stringify(
          formFields[3].value,
        )
      } else {
        addServiceForm.associated_categories = JSON.stringify([])
      }

      addServiceForm.gallery_images = JSON.stringify(formFields[4].value)

      addServiceForm.price = parseFloat(
        formFields[5].value.replace(/,/g, ''),
      ).toString()
      addServiceForm.duration_in_minute = formFields[6].value
      addServiceForm.type = formFields[7].value
      addServiceForm.offered_by = formFields[8].value
      addServiceForm.provider_count = parseInt(formFields[9].value)
      addServiceForm.fee_percentage = formFields[10].value
      addServiceForm.description = formFields[11].value
    }
    Logic.Common.sideBarInfo = Logic.Common.updatedData(
      Logic.Common.sideBarInfo,
      {
        formIsLoading: true,
      },
    )
    Logic.Product.AddService(true)
  }
}

const showAddService = () => {
  Logic.Product.GetAllServiceCategories()

  const serviceProviderTypes: SelectOption[] = [
    {
      key: 'individual',
      value: 'Individual',
    },
    {
      key: 'business',
      value: 'Business',
    },
    {
      key: 'individual_or_business',
      value: 'Individual or business',
    },
  ]

  Logic.Common.showSideBar({
    show: true,
    isForm: true,
    title: 'Add Service',
    formActionLabel: 'Add',
    formAction: saveService,
    formIsLoading: false,
    formField: [
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Upload Service Image',
        placeholder: 'Upload',
        ref: 'image',
        type: 'image',
        validations: [],
        value: '',
        name: 'Name',
        hasTitle: true,
        imageUrl: '',
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Name',
        placeholder: 'Enter service name',
        ref: 'service_name',
        type: 'text',
        validations: [Logic.Form.RequiredRule],
        value: '',
        name: 'Name',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Primary Service Category',
        placeholder: 'Select category',
        ref: 'service_category',
        type: 'select',
        validations: [Logic.Form.RequiredRule],
        selectOption: Logic.Product.ServiceCategoriesOptions,
        value: '',
        name: 'Primary Service Category',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Associated Service Categories',
        placeholder: 'Select category',
        ref: 'service_category',
        type: 'select',
        validations: [Logic.Form.RequiredRule],
        selectOption: Logic.Product.ServiceCategoriesOptions,
        value: '',
        name: 'Associated Service Category',
        hasTitle: true,
        isMultiple: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Service Gallery Images',
        placeholder: '',
        ref: 'service_gallery_images',
        type: 'imageGallery',
        validations: [],
        value: '',
        name: 'Service Gallery Images',
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Service Price',
        placeholder: 'Set price',
        ref: 'service_price',
        type: 'tel',
        validations: [Logic.Form.RequiredRule],
        value: '',
        name: 'Service Price',
        isFormatted: true,
        prefixContent: '₦ ',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Duration in minutes',
        placeholder: 'Set duration',
        ref: 'duration_in_minutes',
        type: 'tel',
        validations: [Logic.Form.RequiredRule],
        value: '',
        name: 'Service Duration',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Service Type',
        placeholder: 'Select type',
        ref: 'service_type',
        type: 'select',
        validations: [Logic.Form.RequiredRule],
        selectOption: serviceType,
        value: '',
        name: 'Service Type',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Service Provider Type',
        placeholder: 'Select provider type',
        ref: 'provider_type',
        type: 'select',
        validations: [Logic.Form.RequiredRule],
        selectOption: serviceProviderTypes,
        value: '',
        name: 'Service Provider Type',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Number of Providers Needed',
        placeholder: 'Set number of service providers',
        ref: 'no_of_service_provider',
        type: 'tel',
        validations: [Logic.Form.RequiredRule],
        value: '1',
        name: 'Service Provider Needed',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Provider Fee in %',
        placeholder: 'Set fee',
        ref: 'fee_percentage',
        type: 'tel',
        validations: [Logic.Form.RequiredRule],
        value: '',
        name: 'Service Provider Fee',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Description',
        placeholder: 'Enter description here...',
        ref: 'service_description',
        type: 'textarea',
        validations: [Logic.Form.RequiredRule],
        value: '',
        name: 'Service Description',
        toolsToUse: [
          'header',
          'checklist',
          'image',
          'list',
          'quote',
          'checklist',
        ],
        hasTitle: true,
      },
    ],
  })
}

// eslint-disable-next-line @typescript-eslint/ban-types
const updateService = (checkFormState: Function) => {
  if (checkFormState()) {
    const updateServiceForm = Logic.Product.UpdateServiceForm
    if (Logic.Common.sideBarInfo.formField) {
      const formFields = Logic.Common.sideBarInfo.formField

      if (typeof formFields[0].value === 'string') {
        updateServiceForm.bg_image = undefined
      } else {
        updateServiceForm.bg_image = formFields[0].value
      }

      updateServiceForm.name = formFields[1].value
      updateServiceForm.service_category_id = formFields[2].value

      if (formFields[3].value) {
        updateServiceForm.associated_categories = JSON.stringify(
          formFields[3].value,
        )
      } else {
        updateServiceForm.associated_categories = JSON.stringify([])
      }

      updateServiceForm.gallery_images = JSON.stringify(formFields[4].value)

      updateServiceForm.price = parseFloat(
        formFields[5].value.replace(/,/g, ''),
      ).toString()
      updateServiceForm.duration_in_minute = formFields[6].value
      updateServiceForm.type = formFields[7].value
      updateServiceForm.offered_by = formFields[8].value
      updateServiceForm.provider_count = parseInt(formFields[9].value)
      updateServiceForm.fee_percentage = formFields[10].value
      updateServiceForm.description = formFields[11].value
    }

    Logic.Common.sideBarInfo = Logic.Common.updatedData(
      Logic.Common.sideBarInfo,
      {
        formIsLoading: true,
      },
    )

    Logic.Product.UpdateService(true)
  }
}

const editService = (service: Service) => {
  const updateServiceForm = Logic.Product.UpdateServiceForm

  updateServiceForm.service_uuid = service.uuid

  const serviceProviderTypes: SelectOption[] = [
    {
      key: 'individual',
      value: 'Individual',
    },
    {
      key: 'business',
      value: 'Business',
    },
    {
      key: 'individual_or_business',
      value: 'Individual or business',
    },
  ]

  Logic.Common.showSideBar({
    show: true,
    isForm: true,
    title: 'Update Service',
    formActionLabel: 'Update',
    formAction: updateService,
    formIsLoading: false,
    formField: [
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Upload Service Image',
        placeholder: 'Upload',
        ref: 'image',
        type: 'image',
        validations: [],
        value: `${service.image_url}`,
        name: 'Name',
        hasTitle: true,
        imageUrl: service.image_url,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Name',
        placeholder: 'Enter service name',
        ref: 'service_name',
        type: 'text',
        validations: [Logic.Form.RequiredRule],
        value: `${service.name}`,
        name: 'Name',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Primary Service Category',
        placeholder: 'Select category',
        ref: 'service_category',
        type: 'select',
        validations: [Logic.Form.RequiredRule],
        selectOption: Logic.Product.ServiceCategoriesOptions,
        value: `${service.service_category_id}`,
        name: 'Primary Service Category',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Associated Service Categories',
        placeholder: 'Select category',
        ref: 'service_category',
        type: 'select',
        validations: [Logic.Form.RequiredRule],
        selectOption: Logic.Product.ServiceCategoriesOptions,
        value: service.associated_categories
          ? JSON.parse(service.associated_categories)
          : [],
        name: 'Associated Service Category',
        hasTitle: true,
        isMultiple: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Service Gallery Images',
        placeholder: '',
        ref: 'service_gallery_images',
        type: 'imageGallery',
        validations: [],
        value: service.gallery_images ? JSON.parse(service.gallery_images) : [],
        name: 'Service Gallery Images',
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Service Price',
        placeholder: 'Set price',
        ref: 'service_price',
        type: 'tel',
        validations: [Logic.Form.RequiredRule],
        value: `${service.price}`,
        name: 'Service Price',
        isFormatted: true,
        prefixContent: '₦ ',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Duration in minutes',
        placeholder: 'Set duration',
        ref: 'duration_in_minutes',
        type: 'tel',
        validations: [Logic.Form.RequiredRule],
        value: `${service.duration_in_minute}`,
        name: 'Service Duration',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Service Type',
        placeholder: 'Select type',
        ref: 'service_type',
        type: 'select',
        validations: [Logic.Form.RequiredRule],
        selectOption: serviceType,
        value: `${service.type}`,
        name: 'Service Type',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Service Provider Type',
        placeholder: 'Select provider type',
        ref: 'provider_type',
        type: 'select',
        validations: [Logic.Form.RequiredRule],
        selectOption: serviceProviderTypes,
        value: `${service.offered_by}`,
        name: 'Service Provider Type',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Number of Providers Needed',
        placeholder: 'Set number of service providers',
        ref: 'no_of_service_provider',
        type: 'tel',
        validations: [Logic.Form.RequiredRule],
        value: `${service.provider_count}`,
        name: 'Service Provider Needed',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Provider Fee in %',
        placeholder: 'Set fee',
        ref: 'fee_percentage',
        type: 'tel',
        validations: [Logic.Form.RequiredRule],
        value: `${service.fee_percentage}`,
        name: 'Service Provider Fee',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Description',
        placeholder: 'Enter description here...',
        ref: 'service_description',
        type: 'textarea',
        validations: [Logic.Form.RequiredRule],
        value: service.description,
        name: 'Service Description',
        toolsToUse: [
          'header',
          'checklist',
          'image',
          'list',
          'quote',
          'checklist',
        ],
        hasTitle: true,
      },
    ],
  })
}

export {
  ServiceCategoryTransactions,
  ServiceCategoryBookings,
  productStatsData,
  transactionData,
  bookingData,
  dropoffStats,
  transactionStats,
  ServiceUsageStatistics,
  productOverview,
  bookingStatData,
  statisticsdatesData,
  serviceTransData,
  serviceBookingData,
  statisticsdates,
  serviceTransdates,
  serviceBookingdates,
  setTransactionStats,
  showAddServiceCategory,
  editService,
  showAddService,
  generateProductStats,
  setBookingStats,
  showEditServiceCategory,
}
