import { Logic } from '@unwind-admin/logic'
import { reactive, ref } from 'vue'

const currentUserType = ref<'user' | 'provider'>('user')

// eslint-disable-next-line @typescript-eslint/ban-types
const addTransaction = (checkFormState: Function) => {
  if (checkFormState()) {
    if (Logic.Common.sideBarInfo.formField) {
      const formFields = Logic.Common.sideBarInfo.formField
      const addTransactionForm = Logic.Admins.CreateTransactionForm

      addTransactionForm.amount = formFields[0].value
      addTransactionForm.type = formFields[1].value
      addTransactionForm.narration = formFields[2].value

      Logic.Admins.CreateTransaction(true, currentUserType.value)
    }
  }
}

const showAddTransaction = (userUUid: string, type: 'user' | 'provider') => {
  currentUserType.value = type
  Logic.Admins.CreateTransactionForm.user_uuid = userUUid

  const transactionTypes = reactive([
    {
      key: 'credit',
      value: 'Credit',
    },
    {
      key: 'debit',
      value: 'Debit',
    },
  ])
  Logic.Common.showSideBar({
    show: true,
    isForm: true,
    title: 'Add a new transaction',
    formActionLabel: 'Add',
    formAction: addTransaction,
    formIsLoading: false,
    formField: [
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Amount',
        placeholder: 'Enter amount',
        ref: 'amount',
        type: 'tel',
        validations: [Logic.Form.RequiredRule],
        selectOption: [],
        value: ``,
        name: 'Amount',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Type',
        placeholder: 'Select type',
        ref: 'select_type',
        type: 'select',
        validations: [Logic.Form.RequiredRule],
        selectOption: transactionTypes,
        value: '',
        name: 'Type',
        hasTitle: true,
      },
      {
        customClass: 'col-span-12 !border-transparent !rounded-[5px]',
        label: 'Narration',
        placeholder: 'Enter reason or narration',
        ref: 'narration',
        type: 'text',
        validations: [Logic.Form.RequiredRule],
        selectOption: [],
        value: ``,
        name: 'Narration',
        hasTitle: true,
      },
    ],
  })
}

export { showAddTransaction }
