
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofButton,
  RoofTextField,
} from '@squareroof-admin/ui-components'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofTextField,
    RoofButton,
  },
  name: 'ResetPasswordPage',
  layout: 'Auth',
  setup() {
    useMeta({
      title: 'Reset Password',
    })

    return {}
  },
})
