
import { defineComponent, onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofTable,
  RoofButton,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofTable,
    RoofButton,
  },
  name: 'ProviderListPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Provider',
        property: 'Providers',
        method: 'GetProviders',
        params: [8, 1, 'CREATED_AT', 'DESC'],
        requireAuth: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Providers List',
    })

    const providerList = Logic.Provider.Providers
    const providerTableRef = ref<any>()

    onMounted(() => {
      scrollToTop()
      setTimeout(() => {
        Logic.Provider.ProviderSource.tableRef = providerTableRef.value
      }, 2000)
    })

    // eslint-disable-next-line @typescript-eslint/ban-types
    const addProviderUser = (checkFormState: Function) => {
      if (checkFormState()) {
        if (Logic.Common.sideBarInfo.formField) {
          const formFields = Logic.Common.sideBarInfo.formField
          const AddNewUserForm = Logic.Admins.AddNewUserForm

          AddNewUserForm.first_name = formFields[0].value
          AddNewUserForm.last_name = formFields[1].value
          AddNewUserForm.email = formFields[2].value
          AddNewUserForm.phone = Logic.Form.getPhoneNumber(
            '+234',
            formFields[3].value
          )
          AddNewUserForm.role = 'provider'

          Logic.Admins.AddNewUser(true)
        }
      }
    }

    const showAddProvider = () => {
      Logic.Common.showSideBar({
        show: true,
        isForm: true,
        title: 'Add a Provider',
        formActionLabel: 'Add',
        formAction: addProviderUser,
        formIsLoading: false,
        formField: [
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'First Name',
            placeholder: 'Enter first name',
            ref: 'first_name',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            selectOption: [],
            value: ``,
            name: 'First Name',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Last Name',
            placeholder: 'Enter last name',
            ref: 'last_name',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            selectOption: [],
            value: ``,
            name: 'Last Name',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Email Address',
            placeholder: 'Enter email',
            ref: 'email',
            type: 'text',
            validations: [Logic.Form.RequiredRule, Logic.Form.EmailRule],
            selectOption: [],
            value: ``,
            name: 'Email Address',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Phone number',
            placeholder: 'Enter phone number e.g 09000000000',
            ref: 'phone_number',
            type: 'tel',
            validations: [Logic.Form.RequiredRule],
            selectOption: [],
            value: ``,
            name: 'Phone number',
            hasTitle: true,
          },
        ],
      })
    }

    return {
      Logic,
      providerList,
      showAddProvider,
      providerTableRef,
    }
  },
})
