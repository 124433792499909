import { ref } from "vue";
import { Logic } from '@unwind-admin/logic'

export const topBarTitle = ref('')
export const tabTitle = ref('')
export const backRoutePath = ref('')

export const scrollToTop = () => {
	window.scroll({
		top: 0,
		left: 0,
		behavior: 'auto'
	});
}

export const FormValidations = Logic.Form

export const selectedSavingType = ref('')
export const selectedInvestmentType = ref('')
export const selectedPlanTab = ref('')

