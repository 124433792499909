
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofIcon,
  RoofNormalText,
  RoofBarChart,
  RoofSelect,
  RoofTable,
  RoofPieChart,
  RoofButton,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'
import {
  generateProductStats,
  productOverview,
  ServiceUsageStatistics,
  setTransactionStats,
  productStatsData,
  transactionStats,
  dropoffStats,
  showAddService,
  transactionData,
  bookingData,
  ServiceCategoryTransactions,
  ServiceCategoryBookings,
  setBookingStats,
  bookingStatData,
  showEditServiceCategory,
  statisticsdates,
  statisticsdatesData,
  serviceTransData,
  serviceTransdates,
  serviceBookingData,
  serviceBookingdates,
  showAddServiceCategory,
} from '../../composables/services'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofIcon,
    RoofNormalText,
    RoofBarChart,
    RoofSelect,
    RoofTable,
    RoofPieChart,
    RoofButton,
  },
  name: 'ServiceIndex',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Product',
        property: 'ProductOverviewData',
        method: 'GetProductOverviewData',
        params: [
          Logic.Common.momentInstance(new Date())
            .subtract(1, 'months')
            .format('YYYY-MM-DD'),
          Logic.Common.momentInstance(new Date()).format('YYYY-MM-DD'),
        ],
        requireAuth: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Services',
    })

    const categoryTableRef = ref<any>()
    const serviceTableRef = ref<any>()

    onMounted(() => {
      productOverview.value = Logic.Product.ProductOverviewData

      ServiceUsageStatistics.value = Logic.Product.ServiceUsageStatistics
      ServiceCategoryBookings.value = Logic.Product.ServiceCategoryBookings
      ServiceCategoryTransactions.value =
        Logic.Product.ServiceCategoryTransactions

      if (productOverview.value) {
        generateProductStats()
        setTransactionStats()
        setBookingStats()
        scrollToTop()

        Logic.Product.watchProperty(
          'ServiceUsageStatistics',
          ServiceUsageStatistics
        )
        Logic.Product.watchProperty(
          'ServiceCategoryBookings',
          ServiceCategoryBookings
        )
        Logic.Product.watchProperty(
          'ServiceCategoryTransactions',
          ServiceCategoryTransactions
        )

        setTimeout(() => {
          Logic.Product.ServiceCategorySource.tableRef = categoryTableRef.value
          Logic.Product.ServiceSource.tableRef = serviceTableRef.value
        }, 2000)
      }

      Logic.Product.GetAllServiceCategories()
    })

    watch(statisticsdatesData, () => {
      if (statisticsdatesData.value) {
        statisticsdates.from = statisticsdatesData.value.from
        statisticsdates.to = statisticsdatesData.value.to

        //
        Logic.Product.GetServiceUsageStatistics(
          statisticsdates.from,
          statisticsdates.to
        )

        statisticsdatesData.value = undefined
      }
    })

    watch(serviceTransData, () => {
      if (serviceTransData.value) {
        serviceTransdates.from = serviceTransData.value.from
        serviceTransdates.to = serviceTransData.value.to

        //
        Logic.Product.GetServiceCategoryTransactions(
          serviceTransdates.from,
          serviceTransdates.to
        )

        serviceTransData.value = undefined
      }
    })

    watch(serviceBookingData, () => {
      if (serviceTransData.value) {
        serviceBookingdates.from = serviceBookingData.value.from
        serviceBookingdates.to = serviceBookingData.value.to

        //
        Logic.Product.GetServiceCategoryBookings(
          serviceBookingdates.from,
          serviceBookingdates.to
        )

        serviceBookingData.value = undefined
      }
    })

    watch(ServiceUsageStatistics, () => {
      generateProductStats()
    })

    return {
      productStatsData,
      productOverview,
      Logic,
      transactionStats,
      dropoffStats,
      categoryTableRef,
      serviceTableRef,
      showAddService,
      transactionData,
      bookingData,
      statisticsdates,
      statisticsdatesData,
      ServiceUsageStatistics,
      ServiceCategoryBookings,
      ServiceCategoryTransactions,
      bookingStatData,
      showEditServiceCategory,
      serviceTransData,
      serviceTransdates,
      serviceBookingdates,
      serviceBookingData,
      showAddServiceCategory,
    }
  },
})
