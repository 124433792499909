
import { capitalize, defineComponent, onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofTable,
  RoofButton,
  RoofNormalText,
  RoofBadge,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'
import { Coupon } from '@unwind-admin/logic/src/gql/graphql'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofButton,
    RoofNormalText,
    RoofBadge,
    RoofTable,
  },
  name: 'CouponsPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Financials',
        property: 'AppliedCoupons',
        method: 'GetAppliedCoupons',
        params: [10, 1, 'CREATED_AT', 'DESC'],
        requireAuth: true,
      },
      {
        domain: 'Financials',
        property: 'Coupons',
        method: 'GetCoupons',
        params: [],
        requireAuth: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Coupons',
    })

    const AppliedCoupons = ref(Logic.Financials.AppliedCoupons)
    const Coupons = ref(Logic.Financials.Coupons)

    onMounted(() => {
      scrollToTop()
      Logic.Financials.watchProperty('AppliedCoupons', AppliedCoupons)
      Logic.Financials.watchProperty('Coupons', Coupons)
      Logic.Product.GetAllServiceCategories()
    })

    // eslint-disable-next-line @typescript-eslint/ban-types
    const createCoupon = (checkFormState: Function) => {
      if (checkFormState()) {
        const createCouponForm = Logic.Financials.CreateCouponForm
        if (Logic.Common.sideBarInfo.formField) {
          const formFields = Logic.Common.sideBarInfo.formField
          createCouponForm.name = formFields[0].value
          createCouponForm.percentage_discount = formFields[1].value
          createCouponForm.code = formFields[2].value
          createCouponForm.service_categories = JSON.stringify(
            formFields[3].value
          )
        }

        Logic.Common.sideBarInfo = Logic.Common.updatedData(
          Logic.Common.sideBarInfo,
          {
            formIsLoading: true,
          }
        )

        Logic.Financials.CreateCoupon(true)
      }
    }

    const showCreateCoupon = () => {
      Logic.Common.showSideBar({
        show: true,
        isForm: true,
        title: 'Create Coupon',
        formActionLabel: 'Create',
        formAction: createCoupon,
        formIsLoading: false,
        formField: [
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Name',
            placeholder: 'Enter coupon name',
            ref: 'coupon_name',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            value: ``,
            name: 'Coupon Name',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Coupon percentage discount',
            placeholder: 'Set discount %',
            ref: 'percentage_discount',
            type: 'tel',
            validations: [Logic.Form.RequiredRule],
            value: ``,
            name: 'Percentage discount',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Coupon code',
            placeholder: 'Set code',
            ref: 'code',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            value: ``,
            name: 'Coupon code',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Permitted Services',
            placeholder: 'Select category',
            ref: 'service_category',
            type: 'select',
            validations: [Logic.Form.RequiredRule],
            selectOption: Logic.Product.ServiceCategoriesOptionsUuid,
            value: [],
            name: 'Permitted Services',
            hasTitle: true,
            isMultiple: true,
          },
        ],
      })
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    const updateCoupon = (checkFormState: Function) => {
      if (checkFormState()) {
        const updateCouponForm = Logic.Financials.UpdateCouponForm
        if (Logic.Common.sideBarInfo.formField) {
          const formFields = Logic.Common.sideBarInfo.formField
          updateCouponForm.name = formFields[0].value
          updateCouponForm.percentage_discount = formFields[1].value
          updateCouponForm.code = formFields[2].value
          updateCouponForm.status = formFields[3].value
          updateCouponForm.service_categories = JSON.stringify(
            formFields[4].value
          )
        }

        Logic.Common.sideBarInfo = Logic.Common.updatedData(
          Logic.Common.sideBarInfo,
          {
            formIsLoading: true,
          }
        )
        Logic.Financials.UpdateCoupon(true)
      }
    }

    const showUpdateCoupon = (coupon: Coupon) => {
      const statusOptions = [
        {
          key: 'active',
          value: 'Active',
        },
        {
          key: 'inactive',
          value: 'Inactive',
        },
      ]

      Logic.Financials.UpdateCouponForm.coupon_uuid = coupon.uuid

      let permittedServices: any = coupon.service_categories

      permittedServices = permittedServices ? JSON.parse(permittedServices) : []

      Logic.Common.showSideBar({
        show: true,
        isForm: true,
        title: 'Edit Coupon',
        formActionLabel: 'Update',
        formAction: updateCoupon,
        formIsLoading: false,
        formField: [
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Name',
            placeholder: 'Enter coupon name',
            ref: 'coupon_name',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            value: `${coupon.name}`,
            name: 'Coupon Name',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Coupon percentage discount',
            placeholder: 'Set discount %',
            ref: 'percentage_discount',
            type: 'tel',
            validations: [Logic.Form.RequiredRule],
            value: `${coupon.percentage_discount}`,
            name: 'Percentage discount',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Coupon code',
            placeholder: 'Set code',
            ref: 'code',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            value: `${coupon.code}`,
            name: 'Coupon code',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Status',
            placeholder: 'Set coupon status',
            ref: 'status',
            type: 'select',
            validations: [Logic.Form.RequiredRule],
            selectOption: statusOptions,
            value: `${coupon.status}`,
            name: 'Coupon status',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Permitted Services',
            placeholder: 'Select category',
            ref: 'service_category',
            type: 'select',
            validations: [Logic.Form.RequiredRule],
            selectOption: Logic.Product.ServiceCategoriesOptionsUuid,
            value: permittedServices,
            name: 'Permitted Services',
            hasTitle: true,
            isMultiple: true,
          },
        ],
      })
    }

    return {
      Logic,
      showCreateCoupon,
      AppliedCoupons,
      Coupons,
      capitalize,
      showUpdateCoupon,
    }
  },
})
