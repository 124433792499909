
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofButton,
  RoofTextField,
  RoofFormWrapper,
} from '@squareroof-admin/ui-components'
import { useRoute } from 'vue-router'
import { FormValidations } from '@/composables'
import { Logic } from '@unwind-admin/logic'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofTextField,
    RoofButton,
    RoofFormWrapper,
  },
  name: 'ActivateAccountPage',
  layout: 'Auth',
  setup() {
    useMeta({
      title: 'Activate Account',
    })

    const formComp = ref<any>(null)

    const passwords = reactive({
      password: '',
      confirm_password: '',
    })
    const route = useRoute()

    const activationCode = ref('')
    const userUuid = ref('')

    onMounted(() => {
      activationCode.value = route.params.code.toString()
      userUuid.value = route.query.uuid.toString()
    })

    const activateAccoount = () => {
      const formState: boolean = formComp.value.validate()

      const ActviateAccountForm = Logic.Auth.ActivateAccountForm

      ActviateAccountForm.otp = activationCode.value
      ActviateAccountForm.user_uuid = userUuid.value
      ActviateAccountForm.password = passwords.confirm_password

      Logic.Auth.ActivateAccount(formState)
    }

    return {
      formComp,
      FormValidations,
      passwords,
      activateAccoount,
    }
  },
  data() {
    return {
      parentRefs: null,
    }
  },
  mounted() {
    const parentRefs: any = this.$refs
    this.parentRefs = parentRefs
  },
})
