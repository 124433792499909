
import { capitalize, defineComponent, onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofTable,
  RoofButton,
  RoofIcon,
  // RoofPageData,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'
import { PageData } from '@unwind-admin/logic/src/logic/types/common'
import { editService } from '@/composables/services'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofTable,
    RoofButton,
    RoofIcon,
    // RoofPageData,
  },
  name: 'ServiceInfoPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Product',
        property: 'EachService',
        method: 'GetService',
        params: [],
        requireAuth: true,
        ignoreProperty: true,
        useRouteId: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Service details',
    })

    const pageData = ref<PageData[]>([])

    const EachService = ref(Logic.Product.EachService)

    const PageBookings = Logic.Booking.PageBookings

    const handleState = (state: string) => {
      if (state == 'active') return 'green'
      if (state == 'inactive') return 'red'
      return 'orange'
    }

    const setPageData = () => {
      pageData.value.length = 0

      // booking info
      pageData.value.push({
        title: 'Service information',
        items: [
          {
            title: 'Name',
            content: `${EachService.value.name}`,
            type: 'plain_string',
          },
          {
            title: 'Service Image',
            content: `${EachService.value.image_url}`,
            type: 'single_image',
          },
          {
            title: 'Gallery Images',
            content: EachService.value.gallery_images
              ? JSON.parse(EachService.value.gallery_images)
              : [],
            type: 'multiple_images',
          },
          {
            title: 'Description',
            content: `${EachService.value.description}`,
            type: 'rich_text',
          },
          {
            title: 'Type',
            content: `${capitalize(
              EachService.value.type.split('_').join(' ')
            )}`,
            type: 'plain_string',
          },
          {
            title: 'Price',
            content: `${Logic.Common.convertToMoney(
              EachService.value.price,
              false,
              '',
              false,
              'NGN '
            )}`,
            type: 'plain_string',
          },
          {
            title: 'Service fee',
            content: `${EachService.value.fee_percentage}%`,
            type: 'plain_string',
          },
          {
            title: 'Duration',
            content: `${EachService.value.duration_in_minute} min`,
            type: 'plain_string',
          },
          {
            title: 'State',
            content: `${capitalize(EachService.value.status)}`,
            color: handleState(EachService.value.status),
            type: 'bagde',
          },
          {
            title: 'Added on',
            content: `${Logic.Common.fomartDate(
              EachService.value.created_at,
              'MMM DD, YYYY, hh:MMa'
            )}`,
            type: 'plain_string',
          },
          {
            title: 'Last updated',
            content: `${Logic.Common.fomartDate(
              EachService.value.updated_at,
              'MMM DD, YYYY, hh:MMa'
            )}`,
            type: 'plain_string',
          },
        ],
      })
    }

    const extraQuery = ` hasService: {
      column: UUID,
      operator: EQ,
      value: "${EachService.value.uuid}"
    }`

    onMounted(() => {
      scrollToTop()
      Logic.Product.GetAllServiceCategories()
      Logic.Product.watchProperty('EachService', EachService)
      setPageData()
    })

    watch(EachService, () => {
      setPageData()
    })

    return {
      Logic,
      pageData,
      EachService,
      PageBookings,
      editService,
      extraQuery,
    }
  },
})
