
import { defineComponent, onMounted } from 'vue'
import { useMeta } from 'vue-meta'
import { RoofHeaderText } from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'

export default defineComponent({
  components: {
    RoofHeaderText,
  },
  name: 'ServiceFinePage',
  layout: 'Dashboard',
  middlewares: {},
  setup() {
    useMeta({
      title: 'Service Fines',
    })

    onMounted(() => {
      scrollToTop()
    })

    return {
      Logic,
    }
  },
})
