
import { capitalize, defineComponent, onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofIcon,
  RoofPageData,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'
import { PageData } from '@unwind-admin/logic/src/logic/types/common'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofIcon,
    RoofPageData,
  },
  name: 'BookingInfoPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Booking',
        property: 'EachBooking',
        method: 'GetBooking',
        params: [],
        requireAuth: true,
        ignoreProperty: true,
        useRouteId: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Booking details',
    })

    const pageData = ref<PageData[]>([])

    const EachBooking = Logic.Booking.EachBooking

    const handleState = (state: string) => {
      if (state == 'completed') return 'green'
      if (state == 'unassigned') return 'purple'
      if (state == 'canceled') return 'red'
      return 'orange'
    }

    const setPageData = () => {
      pageData.value.length = 0

      // booking info
      pageData.value.push({
        title: 'Booking information',
        items: [
          {
            title: 'Client',
            content: `${EachBooking.user.full_name}`,
            type: 'link',
            routeLink: `/users/${EachBooking.user.uuid}`,
          },
          {
            title: 'Service',
            content: `${EachBooking.service.name}`,
            type: 'link',
            routeLink: `/services/${EachBooking.service.uuid}`,
          },
          {
            title: 'Price',
            content: `${Logic.Common.convertToMoney(
              EachBooking.amount,
              false,
              '',
              false,
              'NGN '
            )}`,
            type: 'plain_string',
          },
          {
            title: 'Start time',
            content: `${Logic.Common.fomartDate(
              EachBooking.start_time,
              'MMM DD, YYYY, hh:MMa'
            )}`,
            type: 'plain_string',
          },
          {
            title: 'End time',
            content:
              EachBooking.state == 'completed'
                ? `${Logic.Common.fomartDate(
                    EachBooking.end_time,
                    'MMM DD, YYYY, hh:MMa'
                  )}`
                : 'Not set',
            type: 'plain_string',
          },
          {
            title: 'Status',
            content: `${capitalize(EachBooking.state)}`,
            type: 'bagde',
            color: handleState(EachBooking.state),
          },
          {
            title: 'Created on',
            content: `${Logic.Common.fomartDate(
              EachBooking.created_at,
              'MMM DD, YYYY, hh:MMa'
            )}`,
            type: 'plain_string',
          },
        ],
      })

      // provider info

      if (EachBooking.provider_user) {
        pageData.value.push({
          title: 'Provider information',
          items: [
            {
              title: 'Provider',
              content: `${EachBooking.provider_user.full_name}`,
              type: 'link',
              routeLink: `/providers/${EachBooking.provider_user.uuid}`,
            },
            {
              title: 'Ratings',
              content: `${EachBooking.ratings} star(s)`,
              type: 'plain_string',
            },
            {
              title: 'Tip',
              content: `${Logic.Common.convertToMoney(
                EachBooking.tip,
                false,
                '',
                false,
                'NGN '
              )}`,
              type: 'plain_string',
            },
            {
              title: 'Fines',
              content: 'None',
              type: 'plain_string',
            },
            {
              title: 'Client Review',
              content: EachBooking.reviews.length
                ? EachBooking.reviews[0].message
                : 'No review',
              type: 'plain_string',
            },
          ],
        })
      } else {
        pageData.value.push({
          title: 'Provider information',
          items: [],
        })
      }
    }

    onMounted(() => {
      scrollToTop()
      setPageData()
    })

    return {
      Logic,
      pageData,
    }
  },
})
