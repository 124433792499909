
import { capitalize, defineComponent, onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofIcon,
  RoofPageData,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'
import { PageData } from '@unwind-admin/logic/src/logic/types/common'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofIcon,
    RoofPageData,
  },
  name: 'TransactionInfoPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Financials',
        property: 'EachTransaction',
        method: 'GetTransaction',
        params: [],
        requireAuth: true,
        ignoreProperty: true,
        useRouteId: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Transaction details',
    })

    const pageData = ref<PageData[]>([])

    const EachTransaction = Logic.Financials.EachTransaction

    const handleState = (state: string) => {
      if (state == 'successful' || state == 'credit') return 'green'
      if (state == 'failed' || state == 'debit') return 'red'
      return 'orange'
    }

    const setPageData = () => {
      pageData.value.length = 0

      // booking info
      pageData.value.push({
        title: 'Transaction information',
        items: [
          {
            title: 'User',
            content: `${EachTransaction.user.full_name}`,
            type: 'link',
            routeLink: `/users/${EachTransaction.user.uuid}`,
          },
          {
            title: 'Reference',
            content: `${EachTransaction.reference}`,
            type: 'plain_string',
          },
          {
            title: 'Amount',
            content: `${Logic.Common.convertToMoney(
              EachTransaction.amount,
              false,
              '',
              false,
              'NGN '
            )}`,
            type: 'plain_string',
          },
          {
            title: 'Type',
            content: `${capitalize(EachTransaction.dr_or_cr)}`,
            type: 'bagde',
            color: handleState(EachTransaction.dr_or_cr),
          },
          {
            title: 'Description',
            content: `${EachTransaction.description}`,
            type: 'plain_string',
          },
          {
            title: 'Charge type',
            content: `${capitalize(EachTransaction.chargeable_type)}`,
            type: 'bagde',
            color: 'purple',
          },
          {
            title: 'Charges',
            content: `${Logic.Common.convertToMoney(
              EachTransaction.charges,
              false,
              '',
              false,
              'NGN '
            )}`,
            type: 'plain_string',
          },
          {
            title: 'Gateway',
            content: `${EachTransaction.gateway}`,
            type: 'plain_string',
          },
          {
            title: 'Status',
            content: `${capitalize(EachTransaction.status)}`,
            type: 'bagde',
            color: handleState(EachTransaction.status),
          },

          {
            title: 'Created on',
            content: `${Logic.Common.fomartDate(
              EachTransaction.created_at,
              'MMM DD, YYYY, hh:MMa'
            )}`,
            type: 'plain_string',
          },
        ],
      })
    }

    onMounted(() => {
      scrollToTop()
      setPageData()
    })

    return {
      Logic,
      pageData,
    }
  },
})
