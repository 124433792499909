
import { capitalize, defineComponent, onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  // RoofTable,
  RoofButton,
  RoofIcon,
  RoofPageData,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'
import { PageData } from '@unwind-admin/logic/src/logic/types/common'
import { editService } from '@/composables/services'

export default defineComponent({
  components: {
    RoofHeaderText,
    // RoofTable,
    RoofButton,
    RoofIcon,
    RoofPageData,
  },
  name: 'ReferredUserInfoPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Users',
        property: 'EachReferredUser',
        method: 'GetReferredUser',
        params: [],
        requireAuth: true,
        ignoreProperty: true,
        useRouteId: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Referred User details',
    })

    const pageData = ref<PageData[]>([])

    const EachReferredUser = ref(Logic.Users.EachReferredUser)

    const goToUser = (data) => {
      if (data) {
        Logic.Common.GoToRoute(`../../../users/${data.uuid}`)
      }
    }
    const setPageData = () => {
      pageData.value.length = 0

      // ReferredUser info
      pageData.value.push({
        title: 'Referred User information',
        items: [
          {
            title: 'Name',
            content: `${EachReferredUser.value.user.full_name}`,
            type: 'plain_string',
          },
          {
            title: 'Email',
            content: `${EachReferredUser.value.user.email}`,
            type: 'plain_string',
          },
          {
            title: 'Phone number',
            content: `${EachReferredUser.value.user.phone}`,
            type: 'plain_string',
          },
          {
            title: 'Referrer name',
            content: `${
              EachReferredUser.value.referral_entity == 'influencer'
                ? EachReferredUser.value.influencer.name
                : EachReferredUser.value.referral_entity == 'user'
                ? EachReferredUser.value.referree_profile.user.full_name
                : 'N/A'
            }`,
            type: 'plain_string',
          },
          {
            title: 'Referral type',
            content: `${EachReferredUser.value.referral_entity}`,
            type: 'plain_string',
          },
          {
            title: 'Conversion status',
            content: `${EachReferredUser.value.has_converted}`,
            type: 'plain_string',
          },
          {
            title: 'Joined on',
            content: `${Logic.Common.fomartDate(
              EachReferredUser.value.created_at,
              'MMM DD, YYYY, hh:MMa'
            )}`,
            type: 'plain_string',
          },
        ],
      })
    }

    onMounted(() => {
      scrollToTop()
      Logic.Users.watchProperty('EachReferredUser', EachReferredUser)
      setPageData()
    })

    watch(EachReferredUser, () => {
      setPageData()
    })

    return {
      Logic,
      pageData,
      EachReferredUser,
      editService,
      goToUser,
    }
  },
})
