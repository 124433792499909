
import { capitalize, defineComponent, onMounted, reactive, ref } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofNormalText,
  RoofIcon,
  RoofPageData,
  RoofCheckbox,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'
import { PageData } from '@unwind-admin/logic/src/logic/types/common'
import { Permission } from '@unwind-admin/logic/src/gql/graphql'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofIcon,
    RoofPageData,
    RoofNormalText,
    RoofCheckbox,
  },
  name: 'RoleInfoPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Admins',
        property: 'EachRole',
        method: 'GetRole',
        params: [],
        requireAuth: true,
        ignoreProperty: true,
        useRouteId: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Role details',
    })

    const pageData = ref<PageData[]>([])

    const EachRole = Logic.Admins.EachRole

    const allPermissions = Logic.Admins.EachRole.permissions

    const permissionCategotries = reactive([
      {
        name: 'Services',
        permissions: [
          'view service',
          'create service',
          'update service',
          'view service fine',
          'update service fine',
          'delete service fine',
          'apply service fine',
          'view assessment',
          'create assessment',
        ],
        permissionData: [],
      },
      {
        name: 'Bookings',
        permissions: [
          'view booking',
          'reschedule booking',
          'cancel booking',
          'assign booking',
          'update booking',
        ],
        permissionData: [],
      },
      {
        name: 'User',
        permissions: ['delete account', 'add user', 'view admin user overview'],
        permissionData: [],
      },
      {
        name: 'Provider',
        permissions: [
          'view admin provider overview',
          'review provider application',
          'create provider',
          'update provider',
        ],
        permissionData: [],
      },
      {
        name: 'Admin',
        permissions: [
          'add admin user',
          'view admin service management',
          'view admin transaction overview',
          'view admin notifications',
          'view admin role',
          'create admin role',
          'assign admin role',
          'update admin permission',
        ],
        permissionData: [],
      },
      {
        name: 'Others',
        permissions: ['view scheduled calls', 'scheduled call'],
        permissionData: [],
      },
    ])

    const setPageData = () => {
      // permission data

      permissionCategotries.forEach((category: any) => {
        category.permissions.forEach((item) => {
          const permissionData = allPermissions.filter((eachItem) => {
            return eachItem.name == item
          })

          if (permissionData.length) {
            category.permissionData.push(permissionData[0])
          }
        })
      })

      pageData.value.length = 0

      // booking info
      pageData.value.push({
        title: 'Role information',
        items: [
          {
            title: 'Name',
            content: `${capitalize(EachRole.name.split('_').join(' '))}`,
            type: 'plain_string',
          },
          {
            title: 'Total users',
            content: `${EachRole.users.length}`,
            type: 'plain_string',
          },
          {
            title: 'Created on',
            content: `${Logic.Common.fomartDate(
              EachRole.created_at,
              'MMM DD, YYYY, hh:MMa'
            )}`,
            type: 'plain_string',
          },
        ],
      })
    }

    onMounted(() => {
      scrollToTop()
      setPageData()
    })

    const permissionChanged = (permission: Permission) => {
      const UpdatePermissionInRoleForm = Logic.Admins.UpdatePermissionInRoleForm
      UpdatePermissionInRoleForm.permission_uuid = permission.uuid
      UpdatePermissionInRoleForm.status = permission.status

      Logic.Admins.UpdatePermissionInRole(true)
    }

    return {
      Logic,
      pageData,
      permissionCategotries,
      capitalize,
      permissionChanged,
    }
  },
})
