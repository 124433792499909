
import { capitalize, defineComponent, onMounted, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import {
  RoofHeaderText,
  // RoofTable,
  RoofButton,
  RoofIcon,
  RoofPageData,
} from "@squareroof-admin/ui-components";
import { Logic } from "@unwind-admin/logic";
import { scrollToTop } from "@/composables";
import { PageData } from "@unwind-admin/logic/src/logic/types/common";
import { editService } from "@/composables/services";
import { Influencer } from "@unwind-admin/logic/src/gql/graphql";

export default defineComponent({
  components: {
    RoofHeaderText,
    // RoofTable,
    RoofButton,
    RoofIcon,
    RoofPageData,
  },
  name: "InfluencerInfoPage",
  layout: "Dashboard",
  middlewares: {
    fetchRules: [
      {
        domain: "Users",
        property: "EachInfluencer",
        method: "GetInfluencer",
        params: [],
        requireAuth: true,
        ignoreProperty: true,
        useRouteId: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: "Influencer details",
    });

    const pageData = ref<PageData[]>([]);

    const EachInfluencer = ref(Logic.Users.EachInfluencer);

    const setPageData = () => {
      pageData.value.length = 0;

      // influencer info
      pageData.value.push({
        title: "Influencer information",
        items: [
          {
            title: "Name",
            content: `${EachInfluencer.value.name}`,
            type: "plain_string",
          },
          {
            title: "Email",
            content: `${EachInfluencer.value.email}`,
            type: "plain_string",
          },
          {
            title: "Phone number",
            content: `${EachInfluencer.value.phone_number}`,
            type: "plain_string",
          },
          {
            title: "Referral code",
            content: `${EachInfluencer.value.referral_code}`,
            type: "plain_string",
          },
          {
            title: "Bank",
            content: `${EachInfluencer.value.bank}`,
            type: "plain_string",
          },
          {
            title: "Account number",
            content: `${EachInfluencer.value.bank_account}`,
            type: "plain_string",
          },
          {
            title: "Referred users",
            content: `${EachInfluencer.value.referred_users.length}`,
            type: "plain_string",
          },

          {
            title: "Amount settled",
            content: `${Logic.Common.convertToMoney(
              EachInfluencer.value.amount_settled || 0,
              true,
              "ngn",
              true,
              ""
            )}`,
            type: "plain_string",
          },
          {
            title: "Total earnings",
            content: `${Logic.Common.convertToMoney(
              EachInfluencer.value.total_earnings || 0,
              true,
              "ngn",
              true,
              ""
            )}`,
            type: "plain_string",
          },
          {
            title: "Joined on",
            content: `${Logic.Common.fomartDate(
              EachInfluencer.value.created_at,
              "MMM DD, YYYY, hh:MMa"
            )}`,
            type: "plain_string",
          },
          {
            title: "Last updated on",
            content: `${Logic.Common.fomartDate(
              EachInfluencer.value.updated_at,
              "MMM DD, YYYY, hh:MMa"
            )}`,
            type: "plain_string",
          },
        ],
      });
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const updateInfluencer = (checkFormState: Function) => {
      if (checkFormState()) {
        const updateInfluencerForm = Logic.Users.UpdateInfluencerForm;
        if (Logic.Common.sideBarInfo.formField) {
          const formFields = Logic.Common.sideBarInfo.formField;
          updateInfluencerForm.name = formFields[0].value;
          updateInfluencerForm.email = formFields[1].value;
          updateInfluencerForm.phone_number = formFields[2].value;
          updateInfluencerForm.referral_code = formFields[3].value;
          updateInfluencerForm.bank = formFields[4].value;
          updateInfluencerForm.bank_account = formFields[5].value;
        }

        Logic.Common.sideBarInfo = Logic.Common.updatedData(
          Logic.Common.sideBarInfo,
          {
            formIsLoading: true,
          }
        );
        Logic.Users.UpdateInfluencer(true);
      }
    };

    const showUpdateInfluencer = (influencer: Influencer) => {
      Logic.Users.UpdateInfluencerForm.influencer_uuid = influencer.uuid;

      Logic.Common.showSideBar({
        show: true,
        isForm: true,
        title: "Edit Influencer",
        formActionLabel: "Update",
        formAction: updateInfluencer,
        formIsLoading: false,
        formField: [
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Name",
            placeholder: "Enter name",
            ref: "name",
            type: "text",
            validations: [Logic.Form.RequiredRule],
            value: `${influencer.name}`,
            name: "Name",
            hasTitle: true,
          },
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Email",
            placeholder: "Enter email",
            ref: "email",
            type: "text",
            validations: [Logic.Form.RequiredRule],
            value: `${influencer.email}`,
            name: "Email",
            hasTitle: true,
          },
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Phone number",
            placeholder: "Enter phone number",
            ref: "phone_number",
            type: "text",
            validations: [Logic.Form.RequiredRule],
            value: `${influencer.phone_number}`,
            name: "Phone Number",
            hasTitle: true,
          },
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Referral code",
            placeholder: "Set referral code",
            ref: "referral_code",
            type: "text",
            validations: [Logic.Form.RequiredRule],
            value: `${influencer.referral_code}`,
            name: "Referral code",
            hasTitle: true,
          },
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Bank",
            placeholder: "Enter bank name",
            ref: "bank",
            type: "text",
            validations: [Logic.Form.RequiredRule],
            value: `${influencer.bank}`,
            name: "Bank",
            hasTitle: true,
          },
          {
            customClass: "col-span-12 !border-transparent !rounded-[5px]",
            label: "Account number",
            placeholder: "Enter account number",
            ref: "bank_account",
            type: "text",
            validations: [Logic.Form.RequiredRule],
            value: `${influencer.bank_account}`,
            name: "Bank Account",
            hasTitle: true,
          },
        ],
      });
    };

    onMounted(() => {
      scrollToTop();
      Logic.Users.watchProperty("EachInfluencer", EachInfluencer);
      setPageData();
    });

    watch(EachInfluencer, () => {
      setPageData();
    });

    return {
      Logic,
      pageData,
      EachInfluencer,
      editService,
      showUpdateInfluencer,
    };
  },
});
