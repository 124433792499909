
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  name: "AuthLayout",
  setup() {
    //
  },
});
