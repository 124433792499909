
import { defineComponent, onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofTable,
  RoofButton,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofTable,
    RoofButton,
  },
  name: 'AdminIndexPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Admins',
        property: 'Admins',
        method: 'GetAdmins',
        params: [8, 1, 'CREATED_AT', 'DESC'],
        requireAuth: true,
      },
      {
        domain: 'Admins',
        property: 'Roles',
        method: 'GetRoles',
        params: [8, 1, 'CREATED_AT', 'DESC'],
        requireAuth: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Administrators',
    })

    const allAdmins = Logic.Admins.Admins
    const allRoles = Logic.Admins.Roles

    const adminTableRef = ref<any>()
    const roleTableRef = ref<any>()

    onMounted(() => {
      scrollToTop()
      setTimeout(() => {
        Logic.Admins.RolesSource.tableRef = roleTableRef.value
        Logic.Admins.AdminSource.tableRef = adminTableRef.value
      }, 2000)
      Logic.Admins.GetAllRoles()
    })

    // eslint-disable-next-line @typescript-eslint/ban-types
    const addAdminUser = (checkFormState: Function) => {
      if (checkFormState()) {
        if (Logic.Common.sideBarInfo.formField) {
          const formFields = Logic.Common.sideBarInfo.formField
          const AddAdminForm = Logic.Admins.AddAdminUserForm

          AddAdminForm.first_name = formFields[0].value
          AddAdminForm.last_name = formFields[1].value
          AddAdminForm.email = formFields[2].value
          AddAdminForm.role = formFields[3].value

          Logic.Admins.AddAdminUser(true)
        }
      }
    }

    const showAddAdmin = () => {
      Logic.Common.showSideBar({
        show: true,
        isForm: true,
        title: 'Add a New Admin',
        formActionLabel: 'Add',
        formAction: addAdminUser,
        formIsLoading: false,
        formField: [
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'First Name',
            placeholder: 'Enter first name',
            ref: 'first_name',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            selectOption: [],
            value: ``,
            name: 'First Name',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Last Name',
            placeholder: 'Enter last name',
            ref: 'last_name',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            selectOption: [],
            value: ``,
            name: 'Last Name',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Email Address',
            placeholder: 'Enter email',
            ref: 'email',
            type: 'text',
            validations: [Logic.Form.RequiredRule, Logic.Form.EmailRule],
            selectOption: [],
            value: ``,
            name: 'Email Address',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Admin User Role',
            placeholder: 'Select role',
            ref: 'select_admin_role',
            type: 'select',
            validations: [Logic.Form.RequiredRule],
            selectOption: Logic.Admins.RolesOptions,
            value: '',
            name: 'Admin User Role',
            hasTitle: true,
          },
        ],
      })
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    const createNewRole = (checkFormState: Function) => {
      if (checkFormState()) {
        if (Logic.Common.sideBarInfo.formField) {
          const formFields = Logic.Common.sideBarInfo.formField

          Logic.Admins.CreateRole(formFields[0].value)
        }
      }
    }

    const showAddRole = () => {
      Logic.Common.showSideBar({
        show: true,
        isForm: true,
        title: 'Create a New Role',
        formActionLabel: 'Create',
        formAction: createNewRole,
        formIsLoading: false,
        formField: [
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Role Name',
            placeholder: 'Enter name e.g customer care',
            ref: 'role_name',
            type: 'text',
            validations: [Logic.Form.RequiredRule],
            selectOption: [],
            value: ``,
            name: 'Role Name',
            hasTitle: true,
          },
        ],
      })
    }

    return {
      allAdmins,
      Logic,
      allRoles,
      showAddRole,
      roleTableRef,
      adminTableRef,
      showAddAdmin,
    }
  },
})
