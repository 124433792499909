
import { capitalize, defineComponent, onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'
import {
  RoofHeaderText,
  RoofTable,
  RoofButton,
  RoofIcon,
  RoofPageData,
  RoofNormalText,
} from '@squareroof-admin/ui-components'
import { Logic } from '@unwind-admin/logic'
import { scrollToTop } from '@/composables'
import {
  PageData,
  SelectOption,
} from '@unwind-admin/logic/src/logic/types/common'
import { editService } from '@/composables/services'
import { showAddTransaction } from '@/composables/admin'

export default defineComponent({
  components: {
    RoofHeaderText,
    RoofTable,
    RoofButton,
    RoofIcon,
    RoofPageData,
    RoofNormalText,
  },
  name: 'ProviderInfoPage',
  layout: 'Dashboard',
  middlewares: {
    fetchRules: [
      {
        domain: 'Provider',
        property: 'EachProvider',
        method: 'GetProvider',
        params: [],
        requireAuth: true,
        ignoreProperty: true,
        useRouteId: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: 'Provider details',
    })

    const pageData = ref<PageData[]>([])

    const EachProvider = ref(Logic.Provider.EachProvider)

    const PageBookings = Logic.Provider.PageBookings
    const PageActivities = Logic.Provider.PageActivities

    const handleState = (state: string) => {
      if (state == 'active') return 'green'
      if (state == 'completed') return 'green'
      if (state == 'declined') return 'red'
      if (state == 'inactive') return 'red'
      if (state == 'yes') return 'green'
      if (state == 'no') return 'red'
      return 'orange'
    }

    const providerDocuments = ref(
      EachProvider.value.profile?.provider?.documents
        ? JSON.parse(EachProvider.value.profile.provider.documents)
        : []
    )

    const setPageData = () => {
      pageData.value.length = 0

      if (EachProvider.value) {
        const verificationStatus =
          EachProvider.value.profile?.provider?.verification_status
            .split('_')
            .join(' ')

        // booking info
        pageData.value.push({
          title: 'Provider information',
          items: [
            {
              title: 'Name',
              content: `${EachProvider.value.full_name}`,
              type: 'plain_string',
            },
            {
              title: 'Profile Image',
              content: `${EachProvider.value.profile.photo_url}`,
              type: 'single_image',
            },
            {
              title: 'Email',
              content: `${EachProvider.value.email}`,
              type: 'plain_string',
            },
            {
              title: 'Phone number',
              content: `${EachProvider.value.phone}`,
              type: 'plain_string',
            },
            {
              title: 'Email verified',
              content: `${EachProvider.value.email_verified_at ? 'Yes' : 'No'}`,
              color: handleState(
                EachProvider.value.email_verified_at ? 'yes' : 'no'
              ),
              type: 'bagde',
            },
            {
              title: 'Phone verified',
              content: `${EachProvider.value.phone_verified_at ? 'Yes' : 'No'}`,
              color: handleState(
                EachProvider.value.phone_verified_at ? 'yes' : 'no'
              ),
              type: 'bagde',
            },
            {
              title: 'Verification Status',
              content: `${capitalize(verificationStatus || 'Not set')}`,
              color: handleState(verificationStatus || ''),
              type: 'bagde',
            },
            {
              title: 'Account Status',
              content: `${capitalize(EachProvider.value.status)}`,
              color: handleState(EachProvider.value.status),
              type: 'bagde',
            },
            {
              title: 'Account Verified',
              content: `${capitalize(
                EachProvider.value.profile?.user_verified ? 'yes' : 'no'
              )}`,
              color: handleState(
                EachProvider.value.profile?.user_verified ? 'yes' : 'no'
              ),
              type: 'bagde',
            },
            {
              title: 'Account Type',
              content: `${capitalize(
                EachProvider.value.profile?.provider?.account_type || 'Not set'
              )}`,
              color: 'purple',
              type: 'bagde',
            },
            {
              title: 'Selected Services',
              content: `${
                EachProvider.value.profile.provider?.selected_service_names
                  ? JSON.parse(
                      EachProvider.value.profile.provider.selected_service_names
                    ).join(', ')
                  : 'No service selected yet'
              }`,
              type: 'plain_string',
            },
            {
              title: 'Permitted Services',
              content: `${
                EachProvider.value.profile.provider?.permitted_service_names
                  ? JSON.parse(
                      EachProvider.value.profile.provider
                        .permitted_service_names
                    ).join(', ')
                  : 'No permitted selected yet'
              }`,
              type: 'plain_string',
            },
            {
              title: 'Wallet balance',
              content: `${Logic.Common.convertToMoney(
                EachProvider.value.wallet?.total_balance || 0,
                false,
                '',
                false,
                'NGN '
              )}`,
              type: 'plain_string',
            },
            {
              title: 'Availability Status',
              content: `${
                EachProvider.value.profile.provider?.is_available
                  ? 'Active'
                  : 'Inactive'
              }`,
              color: handleState(
                EachProvider.value.profile.provider?.is_available
                  ? 'active'
                  : 'inactive'
              ),
              type: 'bagde',
            },
            {
              title: 'Joined on',
              content: `${Logic.Common.fomartDate(
                EachProvider.value.created_at,
                'MMM DD, YYYY, hh:MMa'
              )}`,
              type: 'plain_string',
            },
          ],
        })
      }
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    const updateVerificationStatus = (checkFormState: Function) => {
      if (checkFormState()) {
        const updateVerificationForm = Logic.Provider.UpdateProviderStatusForm

        if (Logic.Common.sideBarInfo.formField) {
          const formFields = Logic.Common.sideBarInfo.formField
          updateVerificationForm.verification_status = formFields[0].value
          updateVerificationForm.permitted_services = JSON.stringify(
            formFields[1].value
          )
          updateVerificationForm.provider_uuid =
            EachProvider.value.profile.provider.uuid

          Logic.Provider.UpdateProviderStatus(true)
        }
      }
    }

    const showUpdateVerification = () => {
      const verificatonOptions: SelectOption[] = [
        {
          key: 'in_progress',
          value: 'In progress',
        },
        {
          key: 'declined',
          value: 'Declined',
        },
        {
          key: 'completed',
          value: 'Completed',
        },
      ]

      let providerPermittedServices: any =
        EachProvider.value.profile.provider.permitted_services

      providerPermittedServices = providerPermittedServices
        ? JSON.parse(providerPermittedServices)
        : []

      providerPermittedServices = [...new Set(providerPermittedServices)]

      Logic.Common.showSideBar({
        show: true,
        isForm: true,
        title: 'Update Verification Status',
        formActionLabel: 'Update',
        formAction: updateVerificationStatus,
        formIsLoading: false,
        formField: [
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Verification Status',
            placeholder: 'Select...',
            ref: 'verification_status',
            type: 'select',
            validations: [Logic.Form.RequiredRule],
            selectOption: verificatonOptions,
            value: `${EachProvider.value.profile.provider.verification_status}`,
            name: 'Verification Status',
            hasTitle: true,
          },
          {
            customClass: 'col-span-12 !border-transparent !rounded-[5px]',
            label: 'Permitted Services',
            placeholder: 'Select category',
            ref: 'service_category',
            type: 'select',
            validations: [Logic.Form.RequiredRule],
            selectOption: Logic.Product.ServiceCategoriesOptionsUuid,
            value: providerPermittedServices,
            name: 'Permitted Services',
            hasTitle: true,
            isMultiple: true,
          },
        ],
      })
    }

    const extraQuery = ` hasProviderUser: {
      column: UUID,
      operator: EQ,
      value: "${EachProvider.value.uuid}"
    }`

    onMounted(() => {
      scrollToTop()
      Logic.Provider.watchProperty('EachProvider', EachProvider)
      setPageData()
      Logic.Product.GetAllServiceCategories()
    })

    watch(EachProvider, () => {
      setPageData()
    })

    const changeToClient = () => {
      Logic.Admins.UpdateUserRoleForm.user_uuid = EachProvider.value.uuid
      Logic.Admins.UpdateUserRoleForm.role = 'client'
      Logic.Admins.UpdateUserRole(true)
    }

    const updateIDverification = (status: boolean) => {
      Logic.Admins.UpdateIdentityVerificationForm.status = status
      Logic.Admins.UpdateIdentityVerificationForm.user_uuid =
        EachProvider.value.uuid
      Logic.Admins.UpdateIdentityVerification(true)
    }

    const updateProviderAccountType = () => {
      Logic.Admins.UpdateProviderAccountTypeForm = {
        account_type:
          EachProvider.value.profile?.provider?.account_type == 'individual'
            ? 'business'
            : 'individual',
        user_uuid: EachProvider.value.uuid,
      }

      Logic.Admins.UpdateProviderAccountType(true)
    }

    return {
      Logic,
      pageData,
      EachProvider,
      PageBookings,
      PageActivities,
      extraQuery,
      providerDocuments,
      editService,
      showUpdateVerification,
      changeToClient,
      updateIDverification,
      showAddTransaction,
      capitalize,
      updateProviderAccountType,
    }
  },
})
