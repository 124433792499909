
import AppLayoutDefault from "./AppLayoutDefault.vue";
import Dashboard from "./Dashboard.vue";
import Auth from "./Auth.vue";
import Sample from "./Sample.vue";
import { shallowRef, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "AppLayout",
  setup() {
    const layout = shallowRef();
    const route = useRoute();
    watch(
      () => route.meta,
      (meta) => {
        try {
          if (meta.layout == "Sample") layout.value = Sample;
          if (meta.layout == "Dashboard") layout.value = Dashboard;
          if (meta.layout == "Auth") layout.value = Auth;
        } catch (e) {
          layout.value = AppLayoutDefault;
        }
      },
      { immediate: true }
    );
    return { layout };
  },
};
